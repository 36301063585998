import React from 'react'
import './InstructorComponent.css'

const InstructorComponent = props => (
    <div className = "InstructorComponent">
       
        

        <a href={`/instructor/${props.url}`}>
            <img src={props.image}/>
            <h3>{props.title}</h3>
           

        </a>


    </div>
)

export default InstructorComponent
