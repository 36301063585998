
import './Footer.css'
import React from 'react';
import {Link} from 'react-router-dom';


const Footer = () => {


const navStyle = {
    textDecoration:'none',
    color: 'white'
    
  
};



  return (
    <div className = "LinkGroup">
      
    <nav>
    
      <ul className="nav-links">


        <div className = "CompanyGroup">

        

        <Link style={navStyle} to="/About">
          <li>About us</li>
        </Link>

        <Link style={navStyle} to="/Careers">
          <li>Careers</li>
        </Link>

        <Link style={navStyle} to="/Contact">
          <li>Contact</li>
        </Link>

        </div>





      </ul>
    </nav>
   


   
    


  </div>

  )

}

export default Footer;








