import React, {useState, useEffect} from 'react';
import firebase from '../../firebase';
import {Link} from 'react-router-dom';
import { TimePicker } from 'antd';
import 'antd/dist/antd.css';
import ManageSessionComponent from './ManageSessionComponent';
import './ManageClassDetail.css'
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {getTimezone} from 'countries-and-timezones';
import jstz from 'jstz';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Spin } from 'antd';

function ManageClassDetail(props) {
    const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)

    const history = useHistory();
    //const [startDate, setStartDate] = useState(new Date());
    const [startDate, setStartDate] = useState("");

   
    const [counter, setCounter] = useState(0)
    const db = firebase.firestore()
    const [loadingForDeleteClassButton, setLoadingForDeleteClassButton] = useState(false);
    const [loadingForAddSessionButton, setLoadingForAddSessionButton] = useState(false);

    const [Course, setCourse] = useState([])
    const classID = props.match.params.id
    const format = 'HH:mm';
    const [instructorId, setInstructorId] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [utcOffset, setUtcOffset] = useState("");
    const [timeZone, setTimeZone] = useState("");
    const [zoomLink, setZoomLink] = useState("");
    const [zoomPassword, setZoomPassword] = useState("");
    const [fullDate, setFullDate] = useState("");

  const [selectedDateInUnixTime, setSelectedDateInUnixTime] = useState(parseInt((new Date().getTime()).toFixed(0)));


    const [selectedTimeZone, setSelectedTimeZone] = useState(jstz.determine().name());



    let [dateError, setDateError] = useState("")
    let [timeError, setTimeError] = useState("")
    let [timeZoneError, setTimeZoneError] = useState("")
    let [zoomLinkError, setZoomLinkError] = useState("")
    let [zoomPasswordError, setZoomPasswordError] = useState("")


    const[sessions, setSessions] = useState([]);

    const [stripeConnectedAccountId, setStripeConnectedAccountId] = useState("")



    let currentUserId;

    var user = firebase.auth().currentUser;
  
    if (user) {
      // User is signed in.
      currentUserId = firebase.auth().currentUser.uid
    } else {
      // No user is signed in.
      window.location.replace("https://sincesunday.com/SignInOrSignUp");

    }


  
    useEffect(() => {
        setData()
  
    }, [])
  
    const setData = async() => {
        
        var docRef = await db.collection("persons").doc(currentUserId);
        docRef.get().then(function(doc) {
            if (doc.exists) {
                setStripeConnectedAccountId(doc.data().stripeAccount)
  
            } else {
                console.log("No such document in get data new !");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
  
        
        
    }


    useEffect(() => {
        getSessions();

    }, [counter])
    

    useEffect(() => {
        getData()

    }, [])

    const getData = async() => {
        
        var docRef = await db.collection("classes").doc(classID);

        docRef.get().then(function(doc) {
            if (doc.exists) {
                setCourse(doc.data())
                setInstructorId(doc.data().instructorId)
                
            } else {
                console.log("No such document in get data new !");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }


    const removeSession = () =>{
        return new Promise ((resolve, reject) => {
        // retrive all sessions with this class id first then , delete them 
        db.collection("sessions").where("classId", "==", Course.id)
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, "  => ", doc.data());
                ChangeSessionStatusToCancelled(doc.data())
                updateAllStudentsWithSessionStatus(doc.data())

              
            });
            resolve('lololol  success removeSession')
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
            reject("error removeSession ")
        });

        }

        )

    }




    const emailEachStudentAboutTheClassCancellation = async(studentId, sessionId) => {
        // first email myself, use a hardcoded email wangwenyue13@gmail.com
        // class name, class date, time, time zone, is cancelled, 
        var docRef = await db.collection("persons").doc(studentId).collection('bookings').doc(sessionId);

        docRef.get().then(function(doc) {
            if (doc.exists) {
                console.log("booking Document data:", doc.data());
                emailStudent(doc.data())
                
            } else {
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    const emailStudent = async(booking) => {
        console.log("here we are at emailStudent")
        const classDate = booking.classDate;
        const classTime = booking.classTime;
        // add time zone later, no time zone after the class is cancelled, look into it later
        const className = booking.className;
        const paymentIntentId = booking.paymentIntentId;


        const api_url = `/sendemail/${classDate}/${classTime}/${className}/${paymentIntentId}`;
      
      
        return fetch(api_url, {
          method: "POST", 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
           
          },
          body:JSON.stringify({})
        });   
    }



    const getRefundForEachStudent = (paymentIntentId) => {
        console.log("here we are at getRefundForEachStudent")
        const api_url = `/createRefund/${paymentIntentId}/${stripeConnectedAccountId}`;
      
      
        return fetch(api_url, {
          method: "POST", 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
           
          },
          body:JSON.stringify({})
        });        
    }

     const onDelete = async() =>{
        // change the classStatus in class to cancelled 
        // change the sessionStatus in session to cancelled
        setLoadingForDeleteClassButton(true)


        const response = await UpdateClassStatus()
        console.log("response is ", response)
        const ss = await removeSession()
        console.log("ss is ", ss)
        // wait for the above two functions finish before we can do the following things 
        isChinese == 0 ? 
        window.alert("课程已删除， 请退款给你的学生")
        :

        window.alert("Class deleted, please refund your students through stripe dashboard with payment intent id")
        history.goBack()



        
    }

    const UpdateClassStatus = () =>{
        return new Promise((resolve, reject) => {
            db.collection('classes').doc(Course.id).set({
                className: Course.className,
                price: Course.price,
                maxNumberStudents: Course.maxNumberStudents,
                prerequisite: Course.prerequisite, 
                aboutClass: Course.aboutClass, 
                duration: Course.duration, 
                instructorId: Course.instructorId, 
                instructorEmail: Course.instructorEmail,
                id: Course.id,
                stripeAccount: Course.stripeAccount, 
                classStatus: "cancelled" 
            })
            .then(function() {
                console.log("UpdateClassStatus success!");
                resolve('success')
                
               
            })
            .catch(function(error) {
                console.error("Error writing document: ", error);
                reject('error')
            }); 
        })
    }



    const handleChange = e => {
        //e.preventDefault();
        const {name, value} = e.target;
  
  
        switch (name) {
  
            case 'zoomPassword':
                zoomPasswordError = value.length < 3 ? "please provide zoom password" : "";
                setZoomPassword(value)
                
            break;
  
  
  
  
            case 'zoomLink':
                zoomLinkError = value.length < 3 ? "please provide zoom link" : "";
                setZoomLink(value)
              
            break;
  
           
  
  
        }
        setZoomPasswordError(zoomPasswordError)
        setZoomLinkError(zoomLinkError)
        
        
  
  
      }

      function deleteSession(sessionId,session, e) {
        setSessions(sessions.filter(item => item.id !== sessionId));
        console.log("delete session  : ", sessionId)

        ChangeSessionStatusToCancelled(session)
        updateAllStudentsWithSessionStatus(session)
        isChinese == 0 ? 
        window.alert("此课时已删除， 请退款给你的学生")
        :

        window.alert("Session removed, please refund your students through stripe dashboard with payment intent id")
    
    }

    const updateAllStudentsWithSessionStatus = async(session) => {
        // get all students in studentList subcollection
        db.collection('sessions').doc(session.id).collection('studentList').get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                //getRefundForEachStudent(doc.data().paymentIntentId)
                //getRefundForEachStudent("pi_1HevprE5DbXx8XVMzUm8SNYO")
                updateEachStudentWithSessionStatus(doc.data().studentId, session.id)
                //emailEachStudentAboutTheClassCancellation(doc.data().studentId, session.id)
                
                
                
            });
        });
        
    }

    const updateEachStudentWithSessionStatus = async(studentId, sessionId) => {
        // populate booking first 
        var docRef = await db.collection("persons").doc(studentId).collection('bookings').doc(sessionId);

        docRef.get().then(function(doc) {
            if (doc.exists) {
                console.log("booking Document data:", doc.data());
                updateCancelledStatus(doc.data(), studentId, sessionId)
            } else {
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }



    const updateCancelledStatus = (booking, studentId, sessionId) => {
        db.collection("persons").doc(studentId).collection('bookings').doc(sessionId).set({
            itemId: booking.itemId, 
            classPrice: booking.classPrice, 
            className: booking.className, 
            classDate: booking.classDate, 
            classTime: booking.classTime, 
            timeZone: booking.timeZone,
            classId: booking.classId, 
            sessionId: booking.sessionId, 
            zoomLink: booking.zoomLink,
            zoomPassword: booking.zoomPassword,
            sessionStatus: "cancelled", 
            paymentIntentId: booking.paymentIntentId, 
            UnixTime: booking.UnixTime,
            instructorEmail: booking.instructorEmail, 
            instructorId: booking.instructorId

        })
        .then(function() {
            console.log("updateCancelledStatus:", sessionId);
            // window.alert("Class deleted, please refund your students through stripe dashboard with payment intent id")
       
            // history.goBack()

        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        }); 
    
    }


    const ChangeSessionStatusToCancelled = (session) => {
        

        db.collection('sessions').doc(session.id).set({
            classId: session.classId,
            date: session.date,
            time: session.time, 
            id: session.id,
            instructorId: session.instructorId, 
            instructorEmail: session.instructorEmail,
            className: session.className,
            maxNumberStudents: session.maxNumberStudents, 
            isFull: session.isFull, 
            studentSize : session.studentSize, 
            classPrice : session.classPrice, 
            zoomLink : session.zoomLink, 
            zoomPassword : session.zoomPassword,
            stripeAccount: session.stripeAccount, 
            timeZone: session.timeZone,
            sessionStatus: "cancelled", 
            UnixTime: session.UnixTime

        })
        .then(function() {
            console.log("ChangeSessionStatusToCancelled Document successfully written!");
        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        });
    }


    const renderSessions = sessions.map((session, index) => {
      if(session.sessionStatus !== "cancelled"){
        return (
          <div>
            <ManageSessionComponent
            deleteSession = {deleteSession.bind(this, session.id, session)}
             
              session = {session}
              key = {session.id}
              
            />
          </div>
      )

      }
     
      

    }) 

    const getSessions = async() => {
    

        const citiesRef = db.collection('sessions');
        
  
        const snapshot = await citiesRef.where('classId', '==', classID).where("UnixTime", ">=", selectedDateInUnixTime).orderBy("UnixTime").get();
        if (snapshot.empty) {
          console.log(' No matching documents.');
          setSessions([])
          return;
        }  
        
        let arr = [];
        snapshot.forEach(doc => {
          console.log(' =>', doc.data());
          arr.push(doc.data())
        });
        setSessions(arr)
 
    }



    function timeChange( time, timeString) {
       
        console.log(" time", timeString);
        
        timeError = timeString == null || timeString.length == 0 ? "select a time" : "";
        setTime(timeString)
        setTimeError(timeError)
    }



    function timeZoneChange(selectedTimeZone) {

        console.log("time zone", selectedTimeZone);
        console.log("time zone numbers are ", getTimezone(selectedTimeZone));
        console.log("utc offset string  are ", getTimezone(selectedTimeZone).utcOffsetStr);
        
        setUtcOffset(getTimezone(selectedTimeZone).utcOffsetStr)
       

                
        timeZoneError = selectedTimeZone == null || selectedTimeZone.length == 0 ? "select a timezone" : "";
        setTimeZone(selectedTimeZone)
        setTimeZoneError(timeZoneError)
        
    }

    const formValid = () => {
        let valid = true;
  
        // validate form error
        // if any of them has length > 0, then it's not valid 
        if
        (dateError.length > 0 || 
          timeError.length > 0 || 
          zoomLinkError.length > 0 || 
          zoomPasswordError.length > 0 
         
         ){
            valid = false
        }
  
        // validate form is filled out
        // if any of the field is empty, then can't submit the form, so invalid 
  
  
        if(date.length == 0 ||
          time.length == 0 ||
          zoomLink.length == 0 ||
          zoomPassword.length == 0 
        ){
            valid = false
        }
  
        return valid
    }


    const handleSubmit = e => {
        e.preventDefault();
        if(formValid()){
            // do the sign up steps , like register this user with firebase 
            console.log(`adding session`)
            setLoadingForAddSessionButton(true)
            addSession()
    
            
    
        } else {
            console.log(`form invalid - display error message`)

            console.log(`
            ----current input---
            time : ${time}
            date : ${date}
            zoomPassword : ${zoomPassword}
            zoomLink : ${zoomLink}

            `)


            console.log(`
            ----current input---
            time error : ${timeError}
            date error : ${dateError}
            zoomPassword error : ${zoomPasswordError}
            zoomLink error : ${zoomLinkError}

            `)

            isChinese == 0 ? 
            window.alert("请填好每一栏的信息")
            :

            window.alert("please to make sure to make fill up every field in this page")
    
        }
    }
    


    function dateChange(dateString) {
        
        console.log(" date", dateString);
        
        dateError = dateString == null || dateString.length == 0 ? "select a date" : "";
       
        setStartDate(dateString)
        if(dateString != null){
            const date = dateString.getDate() < 10 ? "0" + dateString.getDate() : dateString.getDate();
       

            let month = dateString.getMonth() + 1
           
            month = month < 10 ? "0" + month : month;
           
            const year = dateString.getFullYear()
    
            const wholeThing = year + "-" + month + "-" + date;
            console.log("whole thing is ", wholeThing)
            setDate(wholeThing)
            setDateError("")

        }else{
            setDateError(dateError)

        }
    }
    

    const addSession = () => {
        
        const randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        const together = date + "T" + time + ":00.000" + utcOffset

       //const together = "2021-01-02T18:00:00.000"
        console.log("everything together is", together)

        const unixTime = Date.parse(together);
        console.log("unix time is", unixTime)
        console.log("date is", date)


        

        const uid = firebase.auth().currentUser.uid
        
        db.collection("sessions").doc(randomString).set({
            // should  pass class id as prop and use it here 
            classId: classID,
            date: date,
            time: time, 
            timeZone: selectedTimeZone,
            id: randomString,
            instructorId: instructorId, 
            instructorEmail: Course.instructorEmail,
            className: Course.className,
            maxNumberStudents: Course.maxNumberStudents, 
            isFull: false, 
            studentSize : 0, 
            classPrice : Course.price, 
            zoomLink : zoomLink, 
            zoomPassword : zoomPassword,
            stripeAccount: Course.stripeAccount, 
            sessionStatus: "available", 
            UnixTime: unixTime
           

        })
        .then(function() {
            console.log("addSession Document successfully written!");

            isChinese == 0 ?
            window.alert("新的课时已保存")
      
            :

            window.alert("New session added")
            setCounter(counter+1)
            setLoadingForAddSessionButton(false)
           
        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        });
    }


  return (

    isChinese == 0 ?


    <div className = "ManageClassDetailGroup">

      
    <h2>管理课程内容</h2>
  
    <h3>课程名称</h3>
    <h4>{Course.className}</h4>

    <h3>人数限制</h3>
    <h4>{Course.maxNumberStudents}</h4>

    <h3>价格</h3>
    <h4>{Course.price} 元</h4>

    <h3>时长</h3>
    <h4>{Course.duration} 分钟</h4>

    <h3>课程内容</h3>
    <h5>{Course.aboutClass}</h5>
  

    <h3>参加此课程的基础前提</h3>
    <h5>{Course.prerequisite}</h5>
 
   

   
    <button  className="commonButton" onClick={() => history.push(`/manageClasses/edit/${classID}`)}>
      编辑
    </button>
    


    <div>
    {loadingForDeleteClassButton ?  <Spin /> : <button className="commonButton" onClick={onDelete} > 删除课程</button>} 

    </div>




    

     <h2>课时</h2>



     
    
     <label htmlFor="date">日期</label>
     <DatePicker selected={startDate} onChange={date => dateChange(date)} />   
     {dateError.length > 0 && (
            <span className = "errorMessage">{dateError}</span>
      )} 
    

    <label htmlFor="time">时间 (24 小时 )</label>
     <TimePicker className = "TimePicker"  format="HH:mm" onChange={timeChange} />
     {timeError.length > 0 && (
            <span className = "errorMessage">{timeError}</span>
     )}

    

     <label htmlFor="zoomLink">腾讯会议链接</label>
      <input
         type="text"
         name = "zoomLink"
         value={zoomLink}
         placeholder="腾讯会议链接 "
         onChange = {handleChange} 
     />
     {zoomLinkError.length > 0 && (
            <span className = "errorMessage">{zoomLinkError}</span>
    )}



    <label htmlFor="zoomPassword">会议密码</label>
    <input type="text" name = "zoomPassword" placeholder="会议密码" value={zoomPassword}
      onChange = {handleChange} />
    {zoomPasswordError.length > 0 && (
            <span className = "errorMessage">{zoomPasswordError}</span>
    )}
   
    
   <div>
    {loadingForAddSessionButton ?  <Spin /> :  <button onClick={handleSubmit} className="commonButton">添加课时</button>} 

    </div>





     


     <div className = "SessionGroup">
       {renderSessions}

     </div>

 

    
  </div>


    :
    <div className = "ManageClassDetailGroup">

      
      <h2>Manage Class Detail</h2>
    
      <h3>Class name</h3>
      <h4>{Course.className}</h4>

      <h3>Max number of students</h3>
      <h4>{Course.maxNumberStudents}</h4>

      <h3>Price in US dollars</h3>
      <h4>$ {Course.price}</h4>

      <h3>Duration </h3>
      <h4>{Course.duration} mins</h4>

      <h3>About this class</h3>
      <h5>{Course.aboutClass}</h5>
    

      <h3>Prerequisite</h3>
      <h5>{Course.prerequisite}</h5>
   
     

     
      <button  className="commonButton" onClick={() => history.push(`/manageClasses/edit/${classID}`)}>
        EDIT
      </button>
      


      <div>
      {loadingForDeleteClassButton ?  <Spin /> : <button className="commonButton" onClick={onDelete} >DELETE THIS CLASS</button>} 

      </div>




      

       <h2>Sessions</h2>



       <h3>We believe you are in {selectedTimeZone} time, students will see time in their time zone </h3>
       
      
       <label htmlFor="date">Date</label>
       <DatePicker selected={startDate} onChange={date => dateChange(date)} />   
       {dateError.length > 0 && (
              <span className = "errorMessage">{dateError}</span>
        )} 
      

      <label htmlFor="time">Time (24 hrs )</label>
       <TimePicker className = "TimePicker"  format="HH:mm" onChange={timeChange} />
       {timeError.length > 0 && (
              <span className = "errorMessage">{timeError}</span>
       )}

      





       <label htmlFor="zoomLink">Zoom link</label>
        <input
           type="text"
           name = "zoomLink"
           value={zoomLink}
           placeholder="Zoom link"
           onChange = {handleChange} 
       />
       {zoomLinkError.length > 0 && (
              <span className = "errorMessage">{zoomLinkError}</span>
      )}



      <label htmlFor="zoomPassword">Zoom Password</label>
      <input type="text" name = "zoomPassword" placeholder="Zoom Password" value={zoomPassword}
        onChange = {handleChange} />
      {zoomPasswordError.length > 0 && (
              <span className = "errorMessage">{zoomPasswordError}</span>
      )}
     
      
     <div>
      {loadingForAddSessionButton ?  <Spin /> :  <button onClick={handleSubmit} className="commonButton">Add</button>} 

      </div>





       


       <div className = "SessionGroup">
         {renderSessions}

       </div>

   

      
    </div>
  );
}

export default ManageClassDetail;