import React, {useState, useEffect} from 'react';
import firebase from '../firebase';
import SessionComponent from './SessionComponent';
import './ClassDetail.css'
import jstz from 'jstz';


function ClassDetail(props) {
    const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)

    const db = firebase.firestore()
    const [selectedTimeZone, setSelectedTimeZone] = useState(jstz.determine().name());


    const [Course, setCourse] = useState([])
    const [Sessions, setSessions] = useState([])
    //const [todayDate, setTodayDate] = useState(new Date(Date.now() - 28e7).getFullYear() + '-' + String(new Date(Date.now() - 28e7).getMonth() + 1).padStart(2, '0') + '-' + String(new Date(Date.now() - 28e7).getDate()).padStart(2, '0'));
    const [selectedDateInUnixTime, setSelectedDateInUnixTime] = useState(parseInt((new Date().getTime()).toFixed(0)));

    

    const classID = props.match.params.id
    console.log("classID : ", classID)

    
    useEffect(() => {
        getCourseData()
        getSessionData()

        console.log("today in unix time ", selectedDateInUnixTime);
        

    }, [])






    const getCourseData = async() => {
        
        var docRef = await db.collection("classes").doc(classID);

        docRef.get().then(function(doc) {
            if (doc.exists) {
                setCourse(doc.data())
                
            } else {
                console.log("No such document in get data new !");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

  


    const getSessionData = async() => {
        //db.collection("sessions").where("classId", "==", classID).orderBy("date")
        db.collection("sessions").where("classId", "==", classID).where('UnixTime', '>=', selectedDateInUnixTime).orderBy("UnixTime")
        .get()
        .then(function(querySnapshot) {
            let arr = []
            querySnapshot.forEach(function(doc) {
                console.log(doc.id, " => ", doc.data());
                console.log( " unix time => ", doc.data().UnixTime);
                
                arr.push(doc.data())
                setSessions(arr)

            });
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });
    }

  const renderSessions = Sessions.map((session) => {
    if(session.sessionStatus !== "cancelled"){
        return (
            <div>
              <SessionComponent
                session = {session}
                course = {Course}
                classID = {classID}
                selectedTimeZone = {selectedTimeZone}
              />
            </div>
        )

      }
      

    }) 



    

    return(

        isChinese == 0 ?

        <div>
        <div className = "ClassInfoGroup">
        
        
        <h3>{Course.className}</h3>
        

        <h6> {Course.price} 元 , {Course.duration} 分钟</h6>

        <h5>关于这个课</h5>
        <h6>{Course.aboutClass}</h6>


        <h5>上这个课的基础前提</h5>
        <h6>{Course.prerequisite}</h6>



        </div>


        <div className = "sessionGroup">
            <h3>课时</h3>
            
   
            <h6> 付款成功后， 你会收到腾讯会议的链接， 请查看你的课程表</h6>
            <h6> {selectedTimeZone} 时区</h6>




            <div className = "sessions">
                {renderSessions}
            </div>

        </div>
        

    </div>


        :

        <div>
            <div className = "ClassInfoGroup">
            
            
            <h3>{Course.className}</h3>
            

            <h6>  ${Course.price} USD, {Course.duration} mins</h6>

            <h5>About this class </h5>
            <h6>{Course.aboutClass}</h6>


            <h5>Prerequisite</h5>
            <h6>{Course.prerequisite}</h6>



            </div>


            <div className = "sessionGroup">
                <h3>Sessions</h3>
                
       
                <h6> You will receive a zoom link under your class schedule after payment goes through</h6>
                <h6>We believe you are in {selectedTimeZone} time</h6>




                <div className = "sessions">
                    {renderSessions}
                </div>

            </div>
            

        </div>
    )
}


  export default ClassDetail;