import React, { useCallback , useState, useEffect} from "react";
import { withRouter } from "react-router";
import app from "../firebase.js";
import firebase from '../firebase';
import './NewSignUp.css'
import { Spin } from 'antd';




const NewSignUp = ({ history }) => {

  const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)

  const [loading, setLoading] = useState(false)

    const db = firebase.firestore()

    const emailRegex = RegExp(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )

    let [userName, setUserName] = useState("")
    let [firstName, setFirstName] = useState("")
    let [lastName, setLastName] = useState("")
    let [email, setEmail] = useState("")
    let [password, setPassword] = useState("")

    
  
    let [userNameError, setUserNameError] = useState("")

    let [firstNameError, setFirstNameError] = useState("")

    let [lastNameError, setLastNameError] = useState("")

    let [emailError, setEmailError] = useState("")

    let [passwordError, setPasswordError] = useState("")




    const handleChange = e => {
        e.preventDefault();
        const {name, value} = e.target;


        switch (name) {

            case 'userName':
                userNameError = value.length < 3 ? "minimum 3 characters required" : "";
                setUserName(value)
                
            break;



            case 'firstName':
                firstNameError = value.length < 3 ? "minimum 3 characters required" : "";
                setFirstName(value)
            break;



            case 'lastName':
                lastNameError = value.length < 3 ? "minimum 3 characters required" : "";
                setLastName(value)
            break;




            case 'email':
                emailError = emailRegex.test(value) ? "" : "incomplete email address";
                setEmail(value)
            break;




            case 'password':
                passwordError = value.length < 6 ? "minimum 6 characters required" : "";
                setPassword(value)
            break;



            default: 
            break;

        }

        setUserNameError(userNameError)
        setFirstNameError(firstNameError)
        setLastNameError(lastNameError)
        setEmailError(emailError)
        setPasswordError(passwordError)


         
    }


    const formValid = (userNameError,firstNameError, lastNameError, emailError, passwordError, userName, firstName, lastName, email, password) => {
        let valid = true;

        // validate form error
        // if any of them has length > 0, then it's not valid 


        if
        (userNameError.length > 0 || 
        firstNameError.length > 0 || 
        lastNameError.length > 0 || 
        emailError.length > 0 || 
        passwordError.length > 0)

        {
            valid = false
        }





        // validate form is filled out
        // if any of the field is empty, then can't submit the form, so invalid 


        if(userName.length == 0 ||
            firstName.length == 0 ||
            lastName.length == 0 ||
            email.length == 0 ||
            password.length == 0
        ){
            valid = false
        }

        return valid
    }


    const createNewUser = ( username, firstname, lastname, email) => {
        var user = firebase.auth().currentUser;
        var uid;
      
        if (user != null) {
         
          uid = user.uid;  
        }
    
        db.collection("persons").doc(uid).set({
          username: username, 
          firstName: firstname, 
          lastName: lastname,
          email: email,  
          pic: "", 
          isInstructor: false, 
          id: uid, 
          isAdmin : false, 
          isInstructor: false,
          stripeAccount: "", 
          showAtFrontPage: false
    
    
    
      })
      .then(function() {
          console.log("createNewUser successfully written!");
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      });
    }


    const handleSignUp = async(userName, firstName, lastName, email, password) => {
        
        
        try {
          await app.auth().createUserWithEmailAndPassword(email, password);
          console.log("creating new user in firestore")
    
            
          createNewUser( userName, firstName, lastName, email)
          console.log("finished sign up, log in ing ")
          history.push("/");

         
        } catch (error) {
          alert(error);
          setLoading(false)
        }
    }

   
    



    const handleSubmit = e => {
        e.preventDefault();
        if(formValid(userNameError,firstNameError, lastNameError, emailError, passwordError, userName, firstName, lastName, email, password)){
            // do the sign up steps , like register this user with firebase 

            setLoading(true)

            handleSignUp( userName, firstName, lastName, email, password)

            

            console.log(`
            ----submitting---
            userName : ${userName}
            firstName : ${firstName}
            lastName : ${lastName}
            email : ${email}
            password : ${password}

            `)
            

        } else {
            console.log(`form invalid - display error message`)

            isChinese == 0 ?

            window.alert("请填好每一栏")
      
            :

            window.alert("form is invalid, plase check error message or simply fill out the sign up form")

            console.log(`
            ----current input---
            userName : ${userName}
            firstName : ${firstName}
            lastName : ${lastName}
            email : ${email}
            password : ${password}

            `)

        }
    }


  return (

    loading ? 

    
    <div className = "NewSignUp">
      <h3>NEW ACCOUNT</h3>
     

      <form   onSubmit={handleSubmit}  noValidate>
        
       


        <div className = "userName">
          <input type = "text" className = " " placeholder = "user name" type = "text" name = "userName" noValidate onChange = {handleChange}></input>
          {userNameError.length > 0 && (
              <span className = "errorMessage">{userNameError}</span>

          )}
        </div>

        <div className = "firstName">
          <input type = "text" className = " " placeholder = "first name" type = "text" name = "firstName" noValidate onChange = {handleChange}></input>
          {firstNameError.length > 0 && (
              <span className = "errorMessage">{firstNameError}</span>

          )}
        </div>

        <div className = "lastName">
          <input type = "text" className = " " placeholder = "last name" type = "text" name = "lastName" noValidate onChange = {handleChange}></input>
          {lastNameError.length > 0 && (
              <span className = "errorMessage">{lastNameError}</span>

          )}
        </div>

        <div className = "email">
          <input type = "text" className = " " placeholder = "email" type = "email" name = "email" noValidate onChange = {handleChange}></input>
          {emailError.length > 0 && (
              <span className = "errorMessage">{emailError}</span>

          )}
        </div>

        <div className = "password">
          <input type = "text" className = " " placeholder = "password" type = "password" autocomplete="new-password" name = "password" noValidate onChange = {handleChange}></input>
          {passwordError.length > 0 && (
              <span className = "errorMessage">{passwordError}</span>

          )}
        </div>


        <div className="example">
          <Spin />
        </div>

      


    
      </form>
      
    </div>


    :


    <div className = "NewSignUp">
      <h3>NEW ACCOUNT</h3>
     

      <form   onSubmit={handleSubmit}  noValidate>
        
       


        <div className = "userName">
          <input type = "text" className = " " placeholder = "user name" type = "text" name = "userName" noValidate onChange = {handleChange}></input>
          {userNameError.length > 0 && (
              <span className = "errorMessage">{userNameError}</span>

          )}
        </div>

        <div className = "firstName">
          <input type = "text" className = " " placeholder = "first name" type = "text" name = "firstName" noValidate onChange = {handleChange}></input>
          {firstNameError.length > 0 && (
              <span className = "errorMessage">{firstNameError}</span>

          )}
        </div>

        <div className = "lastName">
          <input type = "text" className = " " placeholder = "last name" type = "text" name = "lastName" noValidate onChange = {handleChange}></input>
          {lastNameError.length > 0 && (
              <span className = "errorMessage">{lastNameError}</span>

          )}
        </div>

        <div className = "email">
          <input type = "text" className = " " placeholder = "email" type = "email" name = "email" noValidate onChange = {handleChange}></input>
          {emailError.length > 0 && (
              <span className = "errorMessage">{emailError}</span>

          )}
        </div>

        <div className = "password">
          <input type = "text" className = " " placeholder = "password" type = "password" autocomplete="new-password" name = "password" noValidate onChange = {handleChange}></input>
          {passwordError.length > 0 && (
              <span className = "errorMessage">{passwordError}</span>

          )}
        </div>


        <div className = "signUp">
            <button type ="signUp">REGISTER</button>

        </div>

      


    
      </form>
      
    </div>
  );
};

export default withRouter(NewSignUp);