  
import React, { useCallback, useContext, useState} from "react";
import { withRouter, Redirect } from "react-router";
import app from "../firebase.js";
import { AuthContext } from "../Auth.js";
import './Login.css'
import firebase from '../firebase'
import { Spin } from 'antd';

const Login = ({ history }) => {
  localStorage.clear();
  const [loading, setLoading] = useState(false)
  const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)

  const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  )

  let [email, setEmail] = useState("")
  let [password, setPassword] = useState("")


  let [emailError, setEmailError] = useState("")
  let [passwordError, setPasswordError] = useState("")



  const handleChange = e => {
    e.preventDefault();
    const {name, value} = e.target;


    switch (name) {

        case 'email':
            emailError = emailRegex.test(value) ? "" : "incomplete email address";
            setEmail(value)
        break;


        case 'password':
            passwordError = value.length < 6 ? "minimum 6 characters required" : "";
            setPassword(value)
        break;


        default: 
        break;

    }

    setEmailError(emailError)
    setPasswordError(passwordError)
  }


  const formValid = ( emailError, passwordError, email, password) => {
    let valid = true;

    // validate form error
    // if any of them has length > 0, then it's not valid 


    if
    (emailError.length > 0 || 
    passwordError.length > 0)

    {
        valid = false
    }


    // validate form is filled out
    // if any of the field is empty, then can't submit the form, so invalid 


    if(email.length == 0 ||
        password.length == 0
    ){
        valid = false
    }

    return valid
}



const handleSubmit = e => {
  e.preventDefault();
  if(formValid(emailError, passwordError, email, password)){
      // do the sign up steps , like register this user with firebase 

      setLoading(true)
      

      handleLogin(email, password)

      console.log(`
      ----submitting---
      
      email : ${email}
      password : ${password}

      `)
      

  } else {
      console.log(`form invalid - display error message`)

      isChinese == 0 ?
      window.alert("请填好每一栏")

      :
      window.alert("form is invalid, plase check error message or simply fill out the sign in form")

      console.log(`
      ----current input---
      email : ${email}
      password : ${password}

      `)

  }
}


const handleLogin = async(email, password) => {
        
  console.log("trying to log in in lololo")
      
  try {
    await app
      .auth()
      .signInWithEmailAndPassword(email, password);
    history.push("/");
  } catch (error) {
    setLoading(false)
    alert(error);
    
  }
}




  // const { currentUser } = useContext(AuthContext);

  // if (currentUser) {
  //     console.log("signed in lololo")
  //   return <Redirect to="/" />;
  // }

  return (


    loading ? 


    <div className = "Login">


      <h3>ALREADY HAVE AN ACCOUNT?</h3>
     


      <form onSubmit={handleSubmit} noValidate>

   

        <div className = "email">
          <input type = "text" className = " " placeholder = "email" type = "email" name = "email" noValidate onChange = {handleChange}></input>
          {emailError.length > 0 && (
              <span className = "errorMessage">{emailError}</span>

          )}
        </div>

        <div className = "password">
          <input type = "text" className = " " placeholder = "password" type = "password" autocomplete="new-password" name = "password" noValidate onChange = {handleChange}></input>
          {passwordError.length > 0 && (
              <span className = "errorMessage">{passwordError}</span>

          )}
        </div>

        <div className="example">
          <Spin />
        </div>
      
       

        
      </form>
     
    </div>


    :

   

    <div className = "Login">


      <h3>ALREADY HAVE AN ACCOUNT?</h3>
     


      <form onSubmit={handleSubmit} noValidate>

   

        <div className = "email">
          <input type = "text" className = " " placeholder = "email" type = "email" name = "email" noValidate onChange = {handleChange}></input>
          {emailError.length > 0 && (
              <span className = "errorMessage">{emailError}</span>

          )}
        </div>

        <div className = "password">
          <input type = "text" className = " " placeholder = "password" type = "password" autocomplete="new-password" name = "password" noValidate onChange = {handleChange}></input>
          {passwordError.length > 0 && (
              <span className = "errorMessage">{passwordError}</span>

          )}
        </div>

        <input type="submit" value="LOGIN" />
      
       

        
      </form>
     
    </div>
  );
};

export default withRouter(Login);