

  
import React, { useCallback, useContext, useState } from "react";
import { withRouter, Redirect } from "react-router";
import app from "../firebase.js";
import { AuthContext } from "../Auth.js";
import './ResetPassword.css'
import firebase from '../firebase'
import { Spin } from 'antd';

const ResetPassword = ({ history }) => {

  const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)
  localStorage.clear();

  const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  
  )

  const [loading, setLoading] = useState(false)
  
  let [email, setEmail] = useState("")
  let [emailError, setEmailError] = useState("")

  const handleChange = e => {
    e.preventDefault();
    const {name, value} = e.target;

    emailError = emailRegex.test(value) ? "" : "incomplete email address";
    setEmail(value)
    setEmailError(emailError)
    
  }


  const formValid = ( emailError, email) => {
    let valid = true;

    if(emailError.length > 0 || email.length == 0){
        valid = false
    }

    return valid
  }



const handleSubmit = e => {
  e.preventDefault();
  if(formValid(emailError, email)){
      setLoading(true)

      handleResetPassword(email)

      console.log(`
      ----submitting---
      
      email : ${email}
     

      `)
      

  } else {
      console.log(`email invalid - display error message`)

      isChinese == 0 ?
      window.alert("邮箱不完整")

      :

    
      window.alert("email is incomplete")

      console.log(`
      ----current input---
      email : ${email}
     

      `)

  }
}




 
  const handleResetPassword = async(email) => {
    
      try {
        var auth = firebase.auth();
        
        auth.sendPasswordResetEmail(email).then(function() {

          isChinese == 0 ? 
          window.alert("更改密码的邮件已发送")
          :
            window.alert("Reset password email has been sent")
            setLoading(false)
          
        }).catch(function(error) {
          
        });


      } catch (error) {
        setLoading(false)
        alert(error);
      }
  }
 
  



  return (

    loading? 

    <div className = "ResetPassword">


      <h3>RESET PASSWORD</h3>
     


      <form onSubmit={handleSubmit}>


        <div className = "email">
          <input type = "text" className = " " placeholder = "email" type = "email" name = "email" noValidate onChange = {handleChange}></input>
          {emailError.length > 0 && (
              <span className = "errorMessage">{emailError}</span>

          )}
        </div>

        <div className="example">
          <Spin />
        </div>
      
       

        
      </form>
     
    </div>



    :


    <div className = "ResetPassword">


      <h3>RESET PASSWORD</h3>
     


      <form onSubmit={handleSubmit}>


        <div className = "email">
          <input type = "text" className = " " placeholder = "email" type = "email" name = "email" noValidate onChange = {handleChange}></input>
          {emailError.length > 0 && (
              <span className = "errorMessage">{emailError}</span>

          )}
        </div>

        <input type="submit" value="RESET PASSWORD" />
      
       

        
      </form>
     
    </div>
  );
};

export default withRouter(ResetPassword);