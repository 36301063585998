import firebase from "firebase/app";
import "firebase/storage";
import "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyCbEZBDkCC724FXnAmkp1EYVR4-cNv0nYg",
    authDomain: "peachierwebsite.firebaseapp.com",
    databaseURL: "https://peachierwebsite.firebaseio.com",
    projectId: "peachierwebsite",
    storageBucket: "peachierwebsite.appspot.com",
    messagingSenderId: "691892137864",
    appId: "1:691892137864:web:9626b9b08b6686db9e143d"
}


 

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };