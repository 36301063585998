import React from 'react';
import firebase from '../firebase'
import Login from './Login'
import ResetPassword from './ResetPassword'
import './SignInOrSignUp.css'
import NewSignUp from './NewSignUp'



function SignInOrSignUp() {


  return (
    <div className = "wholeBody">

      <Login></Login>

  
  <br></br>

      <a href={`/ResetPassword`}>forgot password?</a>



      <hr></hr>
 

      
      <a href={`/NewSignUp`}>create an account?</a>


     
    </div>
  );
}

export default SignInOrSignUp;