import React from 'react';
import './App.css';
import Nav from './Nav';
import Footer from './Footer/Footer';
import About from './Footer/AboutUs/About';
import Home from './HomePage/Home';
import ClassDetail from './ClassDetailPage/ClassDetail';
import InstructorDetail from './InstructorDetailPage/InstructorDetail';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import StudentSchedule from "./StudentDashboardFolder/StudentSchedule/StudentSchedule";
import { AuthProvider } from "./Auth";
import InstructorSchedule from "./InstructorDashboardFolder/InstructorSchedule/InstructorSchedule";
import ManagePersonalInfo from "./StudentDashboardFolder/ManagePersonalInfo/ManagePersonalInfo";
import ManageClasses from "./InstructorDashboardFolder/ManageClasses/ManageClasses";
import AddClassDetail from "./InstructorDashboardFolder/CreateClass/AddClassDetail";
import EditClassDetail from "./InstructorDashboardFolder/ManageClasses/EditClassDetail";
import ManageClassDetail from "./InstructorDashboardFolder/ManageClasses/ManageClassDetail";
import EditPersonalInfo from "./StudentDashboardFolder/ManagePersonalInfo/EditPersonalInfo";
import StudentList from "./StudentList"
import Users from "./Users"
import StudentDashboard from "StudentDashboardFolder/StudentDashboard"
import InstructorDashboard from "./InstructorDashboardFolder/InstructorDashboard"
import AdminDashboard from "./AdminDashboardFolder/AdminDashboard"
import BecomeAnInstructor from "./BecomeAnInstructor/BecomeAnInstructor"
import SignInOrSignUp from "./SigninOrSignup/SignInOrSignUp"
import ResetPassword from "./SigninOrSignup/ResetPassword"
import NewSignUp from "./SigninOrSignup/NewSignUp"
import Careers from "./Footer/Careers/Careers"
import Contact from "./Footer/ContactUs/Contact"
import AddAdminRole from "./AdminDashboardFolder/AddAdmin/AddAdminRole"
import FAQ from "./Footer/FAQ/FAQ"
import PurchaseHistory from "./StudentDashboardFolder/PurchaseHistory/PurchaseHistory"
import TransactionRecord from "./InstructorDashboardFolder/TransactionRecord/TransactionRecord"
import AddInstructor from "./AdminDashboardFolder/AddInstructor/AddInstructor"
import ClassDetailUnderStudentSchedule from "./StudentDashboardFolder/StudentSchedule/ClassDetailUnderStudentSchedule"
import ClassDetailUnderInstructorSchedule from "./InstructorDashboardFolder/InstructorSchedule/ClassDetailUnderInstructorSchedule"
import ConnectToStripe from "./InstructorDashboardFolder/ConnectToStripe/ConnectToStripe"
import Checkout from "./Checkout"
import CancelAfterCheckoutPage from "./CancelAfterCheckoutPage"
import SuccessAfterCheckoutPage from "./SuccessAfterCheckoutPage"



function App() {
 

  return (
    <AuthProvider>
    <Router>
      <div>
     
        <Nav/>
        <Switch>
          <Route exact path="/" component={Home} />
         
          <Route exact path="/CancelAfterCheckoutPage" component={CancelAfterCheckoutPage} />
          <Route exact path="/SuccessAfterCheckoutPage" component={SuccessAfterCheckoutPage} />
          <Route exact path="/StudentSchedule" component={StudentSchedule} />
          <Route exact path="/InstructorSchedule" component={InstructorSchedule} />
          <Route exact path="/ManagePersonalInfo" component={ManagePersonalInfo} />
          <Route path = "/about" component = {About} />
          <Route path = "/ConnectToStripe" exact component = {ConnectToStripe} />
          <Route path = "/connect/ConnectToStripe" exact component = {ConnectToStripe} />
          <Route path = "/classes/:id" exact component = {ClassDetail} />
          <Route path = "/instructor/:id" exact component = {InstructorDetail} />
          <Route exact path="/manageClasses/:id" component={ManageClassDetail} />
          <Route exact path="/AddClassDetail" component={AddClassDetail} />
          <Route exact path="/EditPersonalInfo" component={EditPersonalInfo} />
          <Route exact path="/manageClasses/edit/:id" component={EditClassDetail} />
          <Route exact path="/manageClasses" component={ManageClasses} />
          <Route exact path="/StudentList/:id" component={StudentList} />
          <Route exact path="/StudentDashboard" component={StudentDashboard} />
          <Route exact path="/InstructorDashboard" component={InstructorDashboard} />
          <Route exact path="/AdminDashboard" component={AdminDashboard} />
          <Route exact path="/BecomeAnInstructor" component={BecomeAnInstructor} />
          <Route exact path="/AddInstructor" component={AddInstructor} />
          <Route exact path="/SignInOrSignUp" component={SignInOrSignUp} />
          <Route exact path="/ResetPassword" component={ResetPassword} />
          <Route exact path="/NewSignUp" component={NewSignUp} />


          <Route exact path="/Careers" component={Careers} />
          <Route exact path="/Users" component={Users} />
          <Route exact path="/Contact" component={Contact} />
          <Route exact path="/AddAdminRole" component={AddAdminRole} />
          <Route exact path="/FAQ" component={FAQ} />
          <Route exact path="/PurchaseHistory" component={PurchaseHistory} />
          <Route exact path="/TransactionRecord" component={TransactionRecord} />
          <Route exact path="/Checkout" component={Checkout} />
          <Route exact path="/ClassDetailUnderStudentSchedule/:id" exact component={ClassDetailUnderStudentSchedule} />
          <Route exact path="/ClassDetailUnderInstructorSchedule/:id" exact component={ClassDetailUnderInstructorSchedule} />

        
        </Switch>
        <Footer className = "Footer"></Footer>
      
       
      </div>
    </Router>
    </AuthProvider>


  )

}
  


export default App;