import React, {useState, useEffect} from 'react';
import firebase from "../../firebase";
import { useHistory } from "react-router-dom";
import 'antd/dist/antd.css';
import ManageClassComponent from './ManageClassComponent';
import './ManageClasses.css'



function ManageClasses() {
  const [Classes, setClasses] = React.useState([])
  const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)

  const history = useHistory();



  useEffect(() => {
    getAllClasses()
  },[])


  const getAllClasses = async() => {

     
    const db = firebase.firestore()

    const citiesRef = db.collection('classes');

    let uid;

    var user = firebase.auth().currentUser;

    if (user) {
      // User is signed in.
      uid = firebase.auth().currentUser.uid
    } else {
      // No user is signed in.
      window.location.replace("https://sincesunday.com/SignInOrSignUp");

    }


    
    const snapshot = await citiesRef.where('instructorId', '==', uid).get();
    if (snapshot.empty) {
      console.log('No matching documents.');
      return;
    }  

    let arr = [];
    snapshot.forEach(doc => {
      console.log(doc.id, ' lolol =>', doc.data());
      arr.push(doc.data())

    });
    console.log("lol array is : ", arr)
    setClasses(arr)
      
}


const renderClasses = Classes.map((course) => {
  if(course.classStatus !== "cancelled"){
    return (
      <div>
              
      <ManageClassComponent
        url={course.id}
        title = {course.className}
       
      />
     
    </div>
  
    )

  }


})



  return (

    isChinese == 0 ?


    <div  className = "ManageClassGroup">
      <h2>管理课程 </h2>
      
  
      {Classes.length === 0?
      <div>
     
      <h2> 没有添加任何课 ...</h2>
      </div> :
      
      <div className = "ClassGroup">
        
         {renderClasses}
      </div>
      
    }
    </div>


    :

    <div  className = "ManageClassGroup">
      <h2>Manage classes </h2>
      
  
      {Classes.length === 0?
      <div>
     
      <h2> no classes posted yet ...</h2>
      </div> :
      
      <div className = "ClassGroup">
        
         {renderClasses}
      </div>
      
    }
    </div>
  );
}

export default ManageClasses;