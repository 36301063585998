import React, {useContext, useState, useEffect} from 'react';
import './SessionComponent.css'
import {Helmet} from "react-helmet";
import { loadStripe } from '@stripe/stripe-js';
import firebase from '../firebase'
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { Spin } from 'antd';
import 'moment/locale/zh-cn' 

const SessionComponent = props => {
    const db = firebase.firestore()
    const stripePromise = loadStripe(process.env.REACT_APP_KEY, { stripeAccount: props.course.stripeAccount })
    const [userDateAndTime, setUserDateAndTime] = useState("")
    const [loading, setLoading] = useState(false)

    const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)
  


    useEffect(() => {
    // instructor time , date and time zone 
    const DATE = props.session.date;
    const TIME = props.session.time;
    const dateAndTime = DATE + " " + TIME;
    console.log("dateAndTime in instructor's local time is  ", dateAndTime)
    const TIMEZONE = props.session.timeZone;
    console.log("instructor's time zone is  ", TIMEZONE)
    var final;



    // when we need to form everything together from the original input 
    var instructorTimeAndDateAndTimezone = moment.tz(dateAndTime, TIMEZONE);

    // when we have the local time zone input from user 
    var selectedTimeZone = props.selectedTimeZone
    console.log("selectedTimeZone :   ", selectedTimeZone)

    var userTimeAndDateAndTimezone  = instructorTimeAndDateAndTimezone.clone().tz(selectedTimeZone);

    // display the time in local time zone 

    if(isChinese == 0){
        final = userTimeAndDateAndTimezone.locale('zh-cn').format('llll') 
        
    }else {
        final = userTimeAndDateAndTimezone.locale('en').format('llll')
    }

  
    console.log("time in userTimeAndDateAndTimezone is  ", final)

    
    setUserDateAndTime(final)

    
    
    },[props.selectedTimeZone])



    let history = useHistory();
    const [loggedIn, setLoggedIn] = useState(false)

  
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            
            setLoggedIn(true)
        }else{
          
            setLoggedIn(false)

        }
    }
    )


    const redirectToStripe = async(event) => {

        if(loggedIn){
            setLoading(true)
            

        const stripe = await stripePromise;
        
        const { sessionId } = await fetchCheckoutSession();
        console.log('sessionId: ' , sessionId);
    
        const { error } = await stripe.redirectToCheckout({
          sessionId,
        });

        }else {
            history.push("/SignInOrSignUp");

        }
    }

    

    const fetchCheckoutSession = async() => {
        let classSessionId = props.session.id;
        let studentId = firebase.auth().currentUser.uid;
        const api_url = `/createCheckoutSession/${classSessionId}/${studentId}`;
        //const api_url = `/createCheckoutSession`;
      
      
        return fetch(api_url, {
          method: "POST", 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
           
          },
          body:JSON.stringify({})
        }).then((res) => res.json());
    }


    return(


        isChinese == 0 ?
        

        props.session.isFull? 

        <div className = "SessionComponent">
            <h4>{userDateAndTime}</h4>

        <h4>满</h4>
        </div>



        :


        loading ?


       

        <div className = "SessionComponent">

        <Helmet>
            <script src="https://js.stripe.com/v3/"></script>          
        </Helmet>
          <h4>{userDateAndTime}</h4>
      
          <Spin />

        </div>


        : 

   
        <div className = "SessionComponent">

        <Helmet>
            <script src="https://js.stripe.com/v3/"></script>          
        </Helmet>
          <h4>{userDateAndTime}</h4>
      

        <button  onClick={redirectToStripe}>
            付款
        </button>

        </div>

        :



        props.session.isFull? 

        <div className = "SessionComponent">
            <h4>{userDateAndTime}</h4>

        <h4>Full</h4>
        </div>



        :


        loading ?


       

        <div className = "SessionComponent">

        <Helmet>
            <script src="https://js.stripe.com/v3/"></script>          
        </Helmet>
          <h4>{userDateAndTime}</h4>
      
          <Spin />

        </div>


        : 

   
        <div className = "SessionComponent">

        <Helmet>
            <script src="https://js.stripe.com/v3/"></script>          
        </Helmet>
          <h4>{userDateAndTime}</h4>
      

        <button  onClick={redirectToStripe}>
            BOOK
        </button>

        </div>

     
    )
}



export default SessionComponent