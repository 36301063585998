
import React, { useState, useEffect } from 'react';


function Users() {

    const[users, setUsers] = useState([])

    useEffect(() => {

        fetch('./users').then(res => res.json()).then(users => setUsers(users))
       
    }, [])



    const renderUsers = users.map((user) => {

        return (
          
          <div>
              <li key = {user.id}>{user.username}</li>
            
          </div>
      
        )
      })



  

  return (
    <div className = "Users">
      <h1>Users</h1>
      {renderUsers}
      
    </div>
  );
}

export default Users;