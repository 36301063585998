import React, { useState} from 'react';
import './ClassComponent.css'

const ClassComponent = props => {

    const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)
  

    return (

        isChinese == 0 ?


        <div className = "ClassComponent">
       
        

        <a href={`/classes/${props.url}`}>

            <div className = "xxx">
            <h5>{props.title}</h5>
            <h5>{props.price}元</h5>

            </div>
            
        </a>


    </div>


        :

        <div className = "ClassComponent">
       
        

        <a href={`/classes/${props.url}`}>

            <div className = "xxx">
            <h5>{props.title}</h5>
            <h5>${props.price}</h5>

            </div>
            
        </a>


    </div>



    )
   

}

 




export default ClassComponent