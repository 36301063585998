import React from 'react';
import './About.css'


function About() {


  return (
    <div className = "AboutUs">
      <h2>Hi, welcome to sincesunday!</h2>

    
      <p > We make it easy for you to book a zoom class from your favorite instructors </p>

    </div>
  );
}

export default About;