import React, {useState} from 'react';
import firebase from '../firebase';
import './BecomeAnInstructor.css'



function BecomeAnInstructor() {
    const [instagramName, setInstagramName] = useState('');


    const handleSave = (e) => {
        e.preventDefault();
        const db = firebase.firestore()
        const uid = firebase.auth().currentUser.uid;
        var email = firebase.auth().currentUser.email;
        

        db.collection("becomeInstructorsRequests").doc(uid).set({
            instagramName: instagramName,
            userID: uid, 
            email: email
        })
        .then(function() {
            console.log("Document successfully written!");
        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        });
    }



  return (
    <div className = "BecomeAnInstructor">
      <h5>Become an instructor</h5>

      <br></br>

    <h6>
1. Create a stripe account with sincesunday on stripe. please email me at talktome@sincesunday.com, 
i will send a link to you to create an account on stripe so that you can accept payment from students.
After that, please email me your stripe account 

    </h6>


    <br></br>

    <h6>2. Update your profile pic on personal info under student dashboard </h6>

    <br></br>

    <h6>3. Start creating class and sessions on instructor dashboard </h6>



      
    </div>
  );
}

export default BecomeAnInstructor;