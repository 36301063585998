import React, {useState, useEffect} from 'react';
import firebase from '../../firebase';
import { storage } from "../../firebase";
import './EditPersonalInfo.css'
import { Spin } from 'antd';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { set } from 'date-fns';
import { useHistory } from "react-router";


function EditPersonalInfo() {
    const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)

    const db = firebase.firestore()
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const history = useHistory();
    
    let uid;

    var user = firebase.auth().currentUser;

    if (user) {
      // User is signed in.
      uid = firebase.auth().currentUser.uid
    } else {
      // No user is signed in.
      window.location.replace("https://sincesunday.com/SignInOrSignUp");

    }


  

 
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [stripeAccount, setStripeAccount] = useState('');
    const [isInstructor, SetIsInstructor] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [showAtFrontPage, setShowAtFrontPage] = useState(false);
    const [image, setImage] = useState(null);
    const [progress, setProgress] = useState(0);





    useEffect(() => {
        getPersonalInfo()
        
    }, [])

    function getPersonalInfo() {
        
        var docRef = db.collection("persons").doc(uid);
  
        docRef.get().then(function(doc) {
            if (doc.exists) {
                console.log("person data:", doc.data());
                setUsername(doc.data().username)
                setFirstName(doc.data().firstName)
                setLastName(doc.data().lastName)
                setEmail(doc.data().email)
                setIsAdmin(doc.data().isAdmin)
                SetIsInstructor(doc.data().isInstructor)
                setStripeAccount(doc.data().stripeAccount)
                setShowAtFrontPage(doc.data().showAtFrontPage)
                setImageUrl(doc.data().pic)
            } else {
          
                // doc.data() will be undefined in this case
                console.log("No such person!");
            }
        }).catch(function(error) {
            console.log("Error getting person data:", error);
        });
  
  
    }

    const formValid = () => {
      let valid = true;
      // validate form is filled out
      // if any of the field is empty, then can't submit the form, so invalid 


      if(username.length == 0 ||
        firstName.length == 0 ||
        lastName.length == 0 ||
        email.length == 0 
        
      ){
          valid = false
      }

      return valid
  }

    const handleSubmit = e => {
      e.preventDefault();
      if(formValid()){
          // do the sign up steps , like register this user with firebase 
          console.log(`saving personal information`)
          setSpinnerLoading(true)
          handleSave()
  
      } else {
          console.log(`form invalid - display error message`)

          isChinese == 0 ? 
          window.alert("请填好每一栏的信息")
          :
          window.alert("please make sure to fill up every field on this page")
  
      }
  }




    const handleSave = () => {

       //updateEmail()
        db.collection('persons').doc(uid).set({
            username: username,
            firstName: firstName,
            lastName: lastName, 
            email: email, 
            id: uid, 
            pic: imageUrl, 
            isInstructor: isInstructor, 
            isAdmin : isAdmin,
            stripeAccount: stripeAccount,
            showAtFrontPage: showAtFrontPage

        })
        .then(function() {
            console.log("Document successfully written!");
            //window.alert("Personal info saved")
            history.goBack()
           
        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        });
        
    }

    function beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    }

    function getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    }


    // function updateEmail() {
    //   console.log("update email")
    //   var user = firebase.auth().currentUser;
    //   user.updateEmail(email)

    // }


    const handleChange = info => {
      if (info.file.status === 'uploading') {
        setLoading(true)
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, imageUrl =>
          setImageUrl(imageUrl),
          setLoading(false)

        
        );
      }
    };

    function gobackHandle(){
      history.goBack()
    }



   
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
  


  return (

    isChinese == 0 ?

    <div className = "EditPersonalInfoGroup">
      
    <form onSubmit={handleSubmit}>

    <div className = "PersonalInfoGroup">
    <h2>编辑个人信息</h2>
   

    <label htmlFor="username">用户名</label>
    <input type="text" placeholder="User name" value={username}
      onChange={(e) => setUsername(e.target.value)} />
   
    

    <label htmlFor="firstname">名</label>
    <input type="text" placeholder="First name" value={firstName}
      onChange={(e) => setFirstName(e.target.value)} />
 
   

    <label htmlFor="lastname">姓</label>
    <input type="text" placeholder="Last name" value={lastName}
      onChange={(e) => setLastName(e.target.value)} />
   
  

    <label htmlFor="email">邮箱 （微信， 手机号）</label>
    <h6>{email}</h6>

{/* 
    <input type="text" placeholder="Email" value={email}
      onChange={(e) => setEmail(e.target.value)} /> 
*/}


    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
    </Upload>

    <div>
      {loading ? <h6>uploading, please wait ...</h6> : ""}
    
    </div>



    <div>
    {spinnerLoading ?  <Spin /> : <input type="submit" value="保存" />} 

    </div>


    

    </div>

 

    </form>
  </div>

  :
    <div className = "EditPersonalInfoGroup">
      
      <form onSubmit={handleSubmit}>

      <div className = "PersonalInfoGroup">
      <h2>Edit personal info</h2>
     

      <label htmlFor="username">User name</label>
      <input type="text" placeholder="User name" value={username}
        onChange={(e) => setUsername(e.target.value)} />
     
      

      <label htmlFor="firstname">First name</label>
      <input type="text" placeholder="First name" value={firstName}
        onChange={(e) => setFirstName(e.target.value)} />
   
     

      <label htmlFor="lastname">Last name</label>
      <input type="text" placeholder="Last name" value={lastName}
        onChange={(e) => setLastName(e.target.value)} />
     
    

      <label htmlFor="email">Email</label>
      <h6>{email}</h6>

{/* 
      <input type="text" placeholder="Email" value={email}
        onChange={(e) => setEmail(e.target.value)} /> 
 */}


      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>

      <div>
        {loading ? <h6>uploading, please wait ...</h6> : ""}
      
      </div>



      <div>
      {spinnerLoading ?  <Spin /> : <input type="submit" value="SAVE" />} 

      </div>


      

      </div>

   

      </form>
    </div>

  );
}

export default EditPersonalInfo;