import React, { useState, useEffect } from 'react';
import '../Dashboard.css'
import firebase from '../firebase';


function InstructorDashboard() {
  const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)


  const [uid, setUid] = useState("");

  useEffect(() => {
    featchPersonalInfo()

  }, [])


  function featchPersonalInfo() {

    

    var user = firebase.auth().currentUser;

    if (user) {
      // User is signed in.
      setUid(firebase.auth().currentUser.uid)
    } else {
      // No user is signed in.
      window.location.replace("https://sincesunday.com/SignInOrSignUp");

    }
  }



  return (

    isChinese == 0 ? 


    <div className = "Dashboard">
      <h2>教课</h2>
      <div className = "DashboardGroup">
      

      <a href={`/manageClasses`}>
        <div className = "DashboardItem">

        <h2>课程管理</h2>
        <h3>更改课程内容， 添加新的课时</h3>
        </div>

       </a>

      
      <a href={`/AddClassDetail`}>
        <div className = "DashboardItem">

        <h2>添加新课</h2>
        <h3>创建一门新课  </h3>
        </div>

       </a>



      <a href={`/InstructorSchedule`}>
        <div className = "DashboardItem">

        <h2>课程表</h2>
        <h3>查看你的教师课程表 </h3>
        </div>

       </a>

      


       <a href={`/instructor/${uid}`}>
        <div className = "DashboardItem">

        <h2>我的主页</h2>
        <h3>查看我的主页 </h3>
        </div>

       </a>

    </div>
    </div>

    :


    <div className = "Dashboard">
      <h2>Teaching</h2>
      <div className = "DashboardGroup">
      

      <a href={`/manageClasses`}>
        <div className = "DashboardItem">

        <h2>Manage classes</h2>
        <h3>Edit class info, add more sessions </h3>
        </div>

       </a>

      
      <a href={`/AddClassDetail`}>
        <div className = "DashboardItem">

        <h2>Add a class</h2>
        <h3>Create a new class   </h3>
        </div>

       </a>



      <a href={`/InstructorSchedule`}>
        <div className = "DashboardItem">

        <h2>My schedule</h2>
        <h3>Check your teaching schedule here </h3>
        </div>

       </a>

      


       <a href={`/instructor/${uid}`}>
        <div className = "DashboardItem">

        <h2>View my teaching profile</h2>
        <h3>Take a look at your teaching page </h3>
        </div>

       </a>

    </div>
    </div>

  );
}


export default InstructorDashboard;