import React, {useState, useEffect} from 'react';
import firebase from '../../firebase'
import TransactionRecordItemComponent from './TransactionRecordItemComponent';
import './TransactionRecord.css'




function TransactionRecord() {
    const db = firebase.firestore()
    const instructorId = firebase.auth().currentUser.uid

    const [allTransactionRecord, setAllTransactionRecord] = useState([])

       
    const getAllTransactionRecord = async() => {
        const purchaseHistory = await db.collection('persons').doc(instructorId).collection('transactionRecord')

        
        purchaseHistory.get().then(function(querySnapshot) {
            let arr = []
            querySnapshot.forEach(function(doc) {
                console.log(doc.id, " => ", doc.data());
                arr.push(doc.data())
            });
            setAllTransactionRecord(arr)
        });
        
    }


    useEffect(() => {
        // Update the document title using the browser API
        getAllTransactionRecord()
    }, []);

    const renderItems = allTransactionRecord.map((item) => {
        

        return (
            <div>
                
              <TransactionRecordItemComponent
                item = {item}
                
              />
            </div>
          )
    }) 
  

  return (
    <div className = "TransactionRecord">
      <h2>Transaction Record</h2>
      {renderItems}

      
    </div>
  );
}

export default TransactionRecord;