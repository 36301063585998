
import firebase from '../../firebase'
import React, {useState, useEffect} from 'react';
import './AddInstructor.css'




require("firebase/functions")

function AddInstructor() {
   
    const functions = firebase.functions();
    const addInstructorRole = functions.httpsCallable('addInstructorRole');


    const [email, setEmail] = useState('');
    const db = firebase.firestore()
    const [requests, setRequests] = useState([])

    useEffect(() => {
        getAllRequests()

    }, [])





    function onChange(checked) {
        console.log(`switch to ${checked}`);
        console.log('type of checkeed is' , typeof(checked));
      


    }

    const makeItHappen = async(id) => {

        let ref = await db.collection("persons").doc(id)
        ref.update({
            isInstructor : true
        })
        .then(function() {
            console.log("person" , {id}, " is an instructor now !");
        })
        .catch(function(error) {
            console.error("Error updating document: ", error);
        });
    }

    const setInstructorRoleInPersonDocument = async(email) => {
        var docRef = await db.collection("persons").where("email", "==", email)
  
        docRef.get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                console.log(doc.id, " lol=> ", doc.data());
  
                makeItHappen(doc.id)
  
            });
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });
      }

    function MakeUserAnInstructor() {
        addInstructorRole({email: email}).then(result => {
            console.log(result)
            setInstructorRoleInPersonDocument(email)

        })
    }



    const getAllRequests = () => {
        db.collection("becomeInstructorsRequests").get().then(function(querySnapshot) {
            let arr = []
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                arr.push(doc.data())
            });
            setRequests(arr)
        });
    }


    

    const renderInstructors = requests.map((r) => {
       

        return (

            <div>
               
            <span class="nowrap">Email : {r.email}.  </span>
            <span class="nowrap">Instagram : {r.instagramName}    </span>
           
        </div>
        )
    })


  return (
    <div className = "AddInstructor">
      <h1>Add instructor</h1>

      <br></br>

           
            <input type="text" placeholder="Email" value={email}
            onChange={(e) => setEmail(e.target.value)} />
    
            <br />
            
    
            <button onClick={MakeUserAnInstructor}>
            Add instructor
            </button>
      <br></br>
      <br></br>

      


      {renderInstructors}
      
    </div>
  );
}

export default AddInstructor;