

import React from 'react'
import './ClassComponentUnderStudentSchedule.css'
import moment from 'moment';
import { useState, useEffect} from 'react';

const ClassComponentUnderStudentSchedule = props => {


    const [finalDateAndTime, setFinalDateAndTime] = useState("")



    useEffect(() => {
    // original input
    const DATE = props.bookingItem.classDate;
    const TIME = props.bookingItem.classTime;
    const dateAndTime = DATE + " " + TIME;
    console.log("dateAndTime in athens is  ", dateAndTime)
    const TIMEZONE = props.bookingItem.timeZone;
    console.log("timezone in athens is  ", TIMEZONE)
    var final;

    // when we need to form everything together from the original input 
    var athens = moment.tz(dateAndTime, TIMEZONE);

    // when we have the local time zone input from user 
    var selectedTimeZone = props.selectedTimeZone
    //var selectedTimeZone = "Asia/Shanghai"
    console.log("selectedTimeZone :   ", selectedTimeZone)

    var isChinese = props.isChinese

    var userTimeAndDateAndTimezone  = athens.clone().tz(selectedTimeZone);

    
    
        // display the time in local time zone 
        if(isChinese == 0){
            final = userTimeAndDateAndTimezone.locale('zh-cn').format('llll') 
            
        }else {
            final = userTimeAndDateAndTimezone.locale('en').format('llll')
        }
    
    
    console.log("time in shanghai is  ", final)

    
    setFinalDateAndTime(final)

    
    
    },[props])


    return (
        props.bookingItem.sessionStatus === "cancelled" ? 



        <div className = "ClassComponentUnderStudentSchedule">
            <a href={`/ClassDetailUnderStudentSchedule/${props.url}`}>
    
            <div className = "zzzz">
            <h4>{props.bookingItem.className}</h4>
            <h4>{"cancelled"}</h4>
            <h4>{finalDateAndTime}</h4>
            
            </div> 
            </a>
        </div>
    
        :
    
        <div className = "ClassComponentUnderStudentSchedule">
            <a href={`/ClassDetailUnderStudentSchedule/${props.url}`}>
    
                <div className = "zzzz">
                <h4>{props.bookingItem.className}</h4>
                <h4>{finalDateAndTime}</h4>

                </div> 
            </a>
        </div>

    )

}







export default ClassComponentUnderStudentSchedule