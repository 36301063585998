
import React from 'react';

import './PurchaseHistoryItemComponent.css'


const PurchaseHistoryItemComponent = props => {
   

    return (
        <div className = "PurchaseHistoryItemComponent">

            <h3>{props.item.className}</h3>
            <h4>Transaction Id : {props.item.transactionId}</h4>
            <h4>Instructor Id : {props.item.instructorId}</h4>
            <h4>Class Date : {props.item.classDate}</h4>
            
            
            


        </div>

    )


}

export default PurchaseHistoryItemComponent