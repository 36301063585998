


import {Link} from 'react-router-dom';

import React from 'react';
import './ConnectToStripe.css'


function ConnectToStripe() {

  
   
  return (
    <div className = "ConnectToStripe">
      <h2>Connect To Stripe</h2>

      



    <Link  className="button" to={{ pathname: "https://connect.stripe.com/oauth/authorize?client_id=ca_HjuPgtuyQUlCTioXOYOw2XHshYGMu9GT&state=123&scope=read_write&response_type=code&stripe_user[email]=zixyzixyzixy@gmail.com&stripe_user[url]=https://sincesunday.com/instructor/pwMZzvvpnMbP6GqKS8uzrRw6cDJ3" }} target="_blank" > Connect to Stripe </Link>


    
    </div>
  );
}

export default ConnectToStripe;