import React, {useState, useEffect} from 'react';
import firebase from '../../firebase';
import './ClassDetailUnderStudentSchedule.css'
import jstz from 'jstz';
import moment from 'moment';

function ClassDetailUnderStudentSchedule(props) {
    const db = firebase.firestore()
    const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)



    const itemId = props.match.params.id
    
    const [bookingItem, setBookingItem] = useState([])
    const [reminderForRefund, setReminderForRefund] = useState("")
    const [selectedTimeZone, setSelectedTimeZone] = useState(jstz.determine().name());
    const [finalDateAndTime, setFinalDateAndTime] = useState("")


    useEffect(() => {
        getBookingItemData()
        
    }, [])

    useEffect(() => {
        getUserDateAndTimeInUserTimeZone(bookingItem)
        
    }, [selectedTimeZone])


    const getBookingItemData = async() => {

        let studentId;

        var user = firebase.auth().currentUser;
    
        if (user) {
          // User is signed in.
          studentId = firebase.auth().currentUser.uid
        } else {
          // No user is signed in.
          window.location.replace("https://sincesunday.com/SignInOrSignUp");
    
        }

        

        console.log("itemId is ", {itemId});

        const docRef = db.collection('persons').doc(studentId).collection('bookings').doc(itemId)

       
        docRef.get().then(function(doc) {
            if (doc.exists) {
                console.log("Document data:", doc.data());
                setBookingItem(doc.data())
                getUserDateAndTimeInUserTimeZone(doc.data())
                if(doc.data().sessionStatus == "cancelled"){
                    setReminderForRefund("Class is cancelled, please contact your instructor for refund with payment intent id")
                }
            } else {
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });

    }

    const getUserDateAndTimeInUserTimeZone = async(booking) => {
        const DATE = booking.classDate;
        const TIME = booking.classTime;
        const dateAndTime = DATE + " " + TIME;
        console.log("dateAndTime in instructor's local time is  ", dateAndTime)
        const TIMEZONE = booking.timeZone;
        console.log("instructor time zone is  ", TIMEZONE)
        var final;

       // when we need to form everything together from the original input 
       var instructorTimeAndDateAndTimezone = moment.tz(dateAndTime, TIMEZONE);

   
       console.log("selectedTimeZone :   ", selectedTimeZone)
       var userTimeAndDateAndTimezone  = instructorTimeAndDateAndTimezone.clone().tz(selectedTimeZone);

       
       // display the time in local time zone 
       if(isChinese == 0){
        final = userTimeAndDateAndTimezone.locale('zh-cn').format('llll') 
        
    }else {
        final = userTimeAndDateAndTimezone.locale('en').format('llll')
    }
   console.log("time in userTimeAndDateAndTimezone is  ", final)

       setFinalDateAndTime(final)
    }

    


  return (
    isChinese == 0 ?


    <div className = "ClassDetail">
    <h3>{bookingItem.className}</h3>
    <h6>{reminderForRefund}</h6>

    <h6>{finalDateAndTime}</h6>


    <h5>腾讯会议链接</h5>
    <h6> {bookingItem.zoomLink}</h6>
    

    <h5>腾讯会议密码</h5>
    <h6>{bookingItem.zoomPassword}</h6>

    <h5>老师微信 </h5>
    <h6>{bookingItem.instructorEmail}</h6>

    <h5>订单号码 </h5>
    <h6>{bookingItem.paymentIntentId}</h6>

    
  </div>


    :


    <div className = "ClassDetail">
      <h3>{bookingItem.className}</h3>
      <h6>{reminderForRefund}</h6>

      <h6>{finalDateAndTime}</h6>


      <h5>Class link</h5>
      <h6> {bookingItem.zoomLink}</h6>
      

      <h5>Password</h5>
      <h6>{bookingItem.zoomPassword}</h6>

      <h5>Instructor email </h5>
      <h6>{bookingItem.instructorEmail}</h6>

      <h5>Payment intent id </h5>
      <h6>{bookingItem.paymentIntentId}</h6>

      
    </div>
  );
}

export default ClassDetailUnderStudentSchedule;