import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import firebase from '../../firebase'
import ClassComponentUnderStudentSchedule from './ClassComponentUnderStudentSchedule';
import './StudentSchedule.css'
import moment from 'moment'
import jstz from 'jstz';
import DatePicker from "react-datepicker";

function StudentSchedule() {
  const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)


  const [startDate, setStartDate] = useState(new Date());
  const db = firebase.firestore()
  const [date, setDate] = useState('');
  const [bookingItems, setBookingItems] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState(jstz.determine().name());

  const [selectedDateInUnixTime, setSelectedDateInUnixTime] = useState(parseInt((new Date().getTime()).toFixed(0)));

  useEffect(() => {
    fetchBookingItems()

  }, [selectedDateInUnixTime])


  const fetchBookingItems = async() => {
    

    let studentId;

    var user = firebase.auth().currentUser;

    if (user) {
      // User is signed in.
      studentId = firebase.auth().currentUser.uid
    } else {
      // No user is signed in.
      window.location.replace("https://sincesunday.com/SignInOrSignUp");

    }





    const citiesRef = db.collection('persons').doc(studentId).collection('bookings')
   
    const snapshot = await citiesRef.where("UnixTime", ">=", selectedDateInUnixTime).orderBy("UnixTime").get();
    if (snapshot.empty) {
      setBookingItems([])
      console.log('No matching documents lol');
      return;
    }
   
    let arr = [];
    snapshot.forEach(doc => {
      console.log('  =>', doc.data());
      arr.push(doc.data())
    });
    setBookingItems(arr)
  }
  
  function dateChange(dateString) {
    setStartDate(dateString)
        
    console.log(" date", dateString);
    
 
   

        const date = dateString.getDate() < 10 ? "0" + dateString.getDate() : dateString.getDate();
   

        let month = dateString.getMonth() + 1
       
        month = month < 10 ? "0" + month : month;
       
        const year = dateString.getFullYear()

        const wholeThing = year + "." + month + "." + date;
        console.log("whole thing is ", wholeThing)

        setDate(wholeThing)
        console.log(" wholeThing is ", wholeThing)
        let unixTime = parseInt((new Date(wholeThing).getTime()).toFixed(0))
        console.log("unixTime is ", unixTime)
        setSelectedDateInUnixTime(unixTime)
  
}




  // function dateChange(date, dateString) {
  //   let array = dateString.split("-")
  //   let newFormat = array[0] + '.' + array[1] + '.' + array[2]
  //   console.log("new format is ", newFormat)
  //   let unixTime = parseInt((new Date(newFormat).getTime()).toFixed(0))
  //   console.log("unixTime is ", unixTime)
  
  //   setSelectedDateInUnixTime(unixTime)
  // }


  const renderBookingItems = bookingItems.map((bookingItem) => {
    console.log("bookingItem is ", {bookingItem})

      return (
        <div>
  
          <ClassComponentUnderStudentSchedule
          isChinese={isChinese}
          url={bookingItem.itemId}
          bookingItem = {bookingItem}
          selectedTimeZone = {selectedTimeZone}
          />
            
        </div>
      )
  })


  return (

    isChinese == 0 ?


    <div className = "StudentSchedule">
      <h2>学生课程表</h2>
      
      <h5>{selectedTimeZone} 时区</h5>

      
    
      <DatePicker selected={startDate} onChange={date => dateChange(date)} />   



    
    {bookingItems.length === 0?

      <div>
      <h5> 没有课， 休息一下吧</h5>
      </div> :
      

      <div className = "BookingItems">
         {renderBookingItems}
      </div>}
 
  </div>

    :
   

    <div className = "StudentSchedule">
      <h2>Student Schedule</h2>
      
      <h5>We believe you are in {selectedTimeZone} time</h5>

      
    
      <DatePicker selected={startDate} onChange={date => dateChange(date)} />   



    
    {bookingItems.length === 0?

      <div>
      <h5> no classes booked, take a break</h5>
      </div> :
      

      <div className = "BookingItems">
         {renderBookingItems}
      </div>}
 
  </div>
  );
}

export default StudentSchedule;