import React from 'react';
import './FAQ.css'



function FAQ() {
  

  return (
    <div className = "FAQ">
      <h2>FAQ</h2>

      

    </div>
  );
}

export default FAQ;