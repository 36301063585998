
import React from 'react'
import './StudentInfoBox.css'
import { useState, useEffect} from 'react';

const StudentInfoBox = props => {




    return (
       

    
        <div className = "StudentInfoBox">
           
            
        
            <div className = "cccc">
                <h4>{props.student.studentName}</h4>
                <h4>{props.student.studentEmail}</h4>
                <h4>{props.student.paymentIntentId}</h4>
            </div>
    
        </div>

    )

}


   


export default StudentInfoBox