import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import moment from 'moment'
import firebase from '../../firebase'
import './InstructorSchedule.css'
import ClassComponentUnderInstructorSchedule from './ClassComponentUnderInstructorSchedule';
import jstz from 'jstz';
import DatePicker from "react-datepicker";

function InstructorSchedule() {
  const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)


  const [startDate, setStartDate] = useState(new Date());
  const [date, setDate] = useState('');


  const db = firebase.firestore()
  const [sessionData, setSessionData] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState(jstz.determine().name());

  const [selectedDateInUnixTime, setSelectedDateInUnixTime] = useState(parseInt((new Date().getTime()).toFixed(0)));

  useEffect(() => {
    fetchSessionData()
    console.log("wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww")
    console.log("selected date in unix time is ", selectedDateInUnixTime)

   

}, [selectedDateInUnixTime])

  let uid;

  var user = firebase.auth().currentUser;

  if (user) {
    // User is signed in.
    uid = firebase.auth().currentUser.uid
  } else {
    // No user is signed in.
    window.location.replace("https://sincesunday.com/SignInOrSignUp");

  }

  function dateChange(dateString) {
    setStartDate(dateString)
        
    console.log(" date", dateString);
    
 
   

        const date = dateString.getDate() < 10 ? "0" + dateString.getDate() : dateString.getDate();
   

        let month = dateString.getMonth() + 1
       
        month = month < 10 ? "0" + month : month;
       
        const year = dateString.getFullYear()

        const wholeThing = year + "." + month + "." + date;
        console.log("whole thing is ", wholeThing)

        setDate(wholeThing)
        console.log(" wholeThing is ", wholeThing)
        let unixTime = parseInt((new Date(wholeThing).getTime()).toFixed(0))
        console.log("unixTime is ", unixTime)
        setSelectedDateInUnixTime(unixTime)
  
}





function timeZoneChange(selectedTimeZone) {

  setSelectedTimeZone(selectedTimeZone)
}


const fetchSessionData = async() => {
  let arr = [];
  await db.collection("sessions").where("instructorId", "==", uid).where('UnixTime', '>=', selectedDateInUnixTime).orderBy("UnixTime")
  .get()
  .then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
          //console.log(doc.id, " => ", doc.data());
          arr.push(doc.data())
      });
  })
  .catch(function(error) {
    setSessionData([])
    console.log("Error getting documents: ", error);

  });

  setSessionData(arr)
  console.log("arr: ", arr);


}
  


const renderSessionData = sessionData.map((s) => {
  console.log("s is", s.date)


    return (
      

      <div>

        <ClassComponentUnderInstructorSchedule

        isChinese={isChinese}
        url={s.id}
        session = {s}
        selectedTimeZone = {selectedTimeZone}
        />
          
      </div>
    )
})



  return (

    isChinese == 0 ?

    <div className = "InstructorSchedule">
      <h2>教师课程表</h2>
      <h6>{selectedTimeZone} 时区</h6>


  
    <DatePicker selected={startDate} onChange={date => dateChange(date)} />   




    {sessionData.length === 0?

      <div>
      <h5> 没有课，休息一下吧</h5>
      </div> :
      
      <div className = "BookingItems">
         {renderSessionData}
      </div>}
 
  </div>

  :
   

    <div className = "InstructorSchedule">
      <h2>Instructor Schedule</h2>
      <h6>We believe you are in {selectedTimeZone} time</h6>


  
    <DatePicker selected={startDate} onChange={date => dateChange(date)} />   




    {sessionData.length === 0?

      <div>
      <h4> no classes booked, take a break</h4>
      </div> :
      
      <div className = "BookingItems">
         {renderSessionData}
      </div>}
 
  </div>
  );
}

export default InstructorSchedule;