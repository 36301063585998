import React, {useState, useEffect} from 'react';
import firebase from '../../firebase'
import './PurchaseHistory.css'
import PurchaseHistoryItemComponent from './PurchaseHistoryItemComponent';





const PurchaseHistory = () => {


    const db = firebase.firestore()
    const studentId = firebase.auth().currentUser.uid

    const [allPurchaseHistory, setAllPurchaseHistory] = useState([])


   
    const getAllPurchaseHistory = async() => {
        const purchaseHistory = await db.collection('persons').doc(studentId).collection('purchaseHistory')

        
        purchaseHistory.get().then(function(querySnapshot) {
            let arr = []
            querySnapshot.forEach(function(doc) {
                console.log(doc.id, " => ", doc.data());
                arr.push(doc.data())
            });
            setAllPurchaseHistory(arr)
        });
        
    }

    useEffect(() => {
        // Update the document title using the browser API
        getAllPurchaseHistory()
    }, []);

    const renderItems = allPurchaseHistory.map((item) => {
        

        return (
            <div>
                
              <PurchaseHistoryItemComponent
                item = {item}
                
              />
            </div>
          )
    }) 



 

  return (
    <div className = "PurchaseHistory">
      <h2>Purchase history</h2>
      
      {renderItems}

    

     
    </div>
  );
}

export default PurchaseHistory;