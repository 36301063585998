import './Contact.css'
import React from 'react';



function Contact() {

    const pic = "https://firebasestorage.googleapis.com/v0/b/peachierwebsite.appspot.com/o/images%2FIMG-1481.jpg?alt=media&token=394e8473-486d-4824-8962-5b78f97cd4b6"


    return (
      <div className = "ContactPage">
        <h2>Contact us</h2>
  
       
        <p>talktome@sincesunday.com</p>

        

      
      </div>
    );
  }

export default Contact;