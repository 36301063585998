import React, {useState, useEffect} from 'react';
import firebase from '../../firebase';
import './ManageSessionComponent.css'
import jstz from 'jstz';
import moment from 'moment';

const ManageSessionComponent = props => {
    const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)

    const [selectedTimeZone, setSelectedTimeZone] = useState(jstz.determine().name());
    const db = firebase.firestore()
    const [userDateAndTime, setUserDateAndTime] = useState("")
    const [sessionId, setSessionId] = useState(props.session.id)



    const [stripeConnectedAccountId, setStripeConnectedAccountId] = useState("")
    const currentUserId = firebase.auth().currentUser.uid
  
    useEffect(() => {
        setData()
  
    }, [])

    useEffect(() => {
        // instructor time , date and time zone 
        const DATE = props.session.date;
        const TIME = props.session.time;
        const dateAndTime = DATE + " " + TIME;
        console.log("dateAndTime in instructor's local time is  ", dateAndTime)
        const TIMEZONE = props.session.timeZone;
        console.log("instructor's time zone is  ", TIMEZONE)
        var final;
    
    
        // when we need to form everything together from the original input 
        var instructorTimeAndDateAndTimezone = moment.tz(dateAndTime, TIMEZONE);
    
        // when we have the local time zone input from user 
       
        console.log("selectedTimeZone :   ", selectedTimeZone)
    
        var userTimeAndDateAndTimezone  = instructorTimeAndDateAndTimezone.clone().tz(selectedTimeZone);

    // display the time in local time zone 

    if(isChinese == 0){
        final = userTimeAndDateAndTimezone.locale('zh-cn').format('llll') 
        
    }else {
        final = userTimeAndDateAndTimezone.locale('en').format('llll')
    }
    
        // display the time in local time zone 
        final = userTimeAndDateAndTimezone.format('llll')
        console.log("time in userTimeAndDateAndTimezone is  ", final)
    
        
        setUserDateAndTime(final)
    
        
        
    },[])

  
    const setData = async() => {
        
        var docRef = await db.collection("persons").doc(currentUserId);
        docRef.get().then(function(doc) {
            if (doc.exists) {
                setStripeConnectedAccountId(doc.data().stripeAccount)
  
            } else {
                console.log("No such document in get data new !");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
  
        
        
    }


    const emailEachStudentAboutTheClassCancellation = async(studentId, sessionId) => {
        // first email myself, use a hardcoded email wangwenyue13@gmail.com
        // class name, class date, time, time zone, is cancelled, 
        var docRef = await db.collection("persons").doc(studentId).collection('bookings').doc(sessionId);

        docRef.get().then(function(doc) {
            if (doc.exists) {
                console.log("booking Document data:", doc.data());
                emailStudent(doc.data())
                
            } else {
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    

    const emailStudent = async(booking) => {
        console.log("here we are at emailStudent")
        const classDate = booking.classDate;
        const classTime = booking.classTime;
        // add time zone later, no time zone after the class is cancelled, look into it later
        const className = booking.className;
        const paymentIntentId = booking.paymentIntentId;


        const api_url = `/sendemail/${classDate}/${classTime}/${className}/${paymentIntentId}`;
      
      
        return fetch(api_url, {
          method: "POST", 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
           
          },
          body:JSON.stringify({})
        });   
    }


    const getRefundForEachStudent = async(paymentIntentId) => {
        console.log("here we are at getRefundForEachStudent")
        const api_url = `/createRefund/${paymentIntentId}/${stripeConnectedAccountId}`;
      
      
        return fetch(api_url, {
          method: "POST", 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
           
          },
          body:JSON.stringify({})
        });        
    }



    return (

        isChinese == 0 ?



        <div className = "ManageSessionComponent">
        <h4>{userDateAndTime}</h4>

        <button onClick={props.deleteSession}>
            删除
        </button>
        </div>


        :


        <div className = "ManageSessionComponent">
        <h4>{userDateAndTime}</h4>

        <button onClick={props.deleteSession}>
            Remove
        </button>
        </div>

    )
}

export default ManageSessionComponent