

import React, {useState} from 'react';
import firebase from '../../firebase'
import './AddAdminRole.css'
require("firebase/functions")

function AddAdminRole() {
    const functions = firebase.functions();
    const addAdminRole = functions.httpsCallable('addAdminRole');

    const [email, setEmail] = useState('');
    const db = firebase.firestore()


    const setAdminRoleInPersonDocument = async(email) => {
      var docRef = await db.collection("persons").where("email", "==", email)

      docRef.get().then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
              console.log(doc.id, " lol=> ", doc.data());

              makeItHappen(doc.id)

          });
      })
      .catch(function(error) {
          console.log("Error getting documents: ", error);
      });
    }

    const makeItHappen = async(id) => {

      let ref = await db.collection("persons").doc(id)
      ref.update({
          isAdmin : true
      })
      .then(function() {
          console.log("person" , {id}, " is an admin now !");
      })
      .catch(function(error) {
          console.error("Error updating document: ", error);
      });
    }

    function MakeUserAnAdmin() {
        

        addAdminRole({email: email}).then(result => {
            console.log(result)
            setAdminRoleInPersonDocument(email)

        })
    }
  

  return (
    <div className = "AddAdmin">
      <h2>Add admin </h2>
    

            
            <input type="text" placeholder="Email" value={email}
            onChange={(e) => setEmail(e.target.value)} />
    
       
            
    
            <button onClick={MakeUserAnAdmin}>
            Add admin
            </button>



    

      
    </div>
  );
}

export default AddAdminRole;