
import './SuccessAfterCheckoutPage.css'

import React from 'react';


function SuccessAfterCheckoutPage() {
  // const pic = "https://firebasestorage.googleapis.com/v0/b/peachierwebsite.appspot.com/o/images%2FIMG-1481.jpg?alt=media&token=394e8473-486d-4824-8962-5b78f97cd4b6"


  return (
    <div className = "AboutPage">
      <h6>Successfully booked class, please wait for 2 minutes before checking your class schedule</h6>

      {/* <img src= {pic}  width="350" height="350"  />

       */}
    </div>
  );
}

export default SuccessAfterCheckoutPage;