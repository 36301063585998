import React, {useState, useEffect} from 'react';
import Stripe from 'stripe';
import './Checkout.css';
import {Helmet} from "react-helmet";
import { loadStripe } from '@stripe/stripe-js';



const stripePromise = loadStripe(process.env.REACT_APP_KEY, { stripeAccount: "acct_1HHp30G8zUIgTp88" })

function Checkout() {


  const fetchCheckoutSession = async() => {
    const classId = "60snhioiwjbk4kctl2p0m";
    const api_url = `http://localhost:4242/createCheckoutSession/${classId}`;
  
    // change it to https when using sincesunday url 
    //return fetch(`https://sincesunday.com/createCheckoutSession`, {
    return fetch(api_url, {
      method: "POST", 
      headers: {
        'Content-Type': 'application/json'
       
      },
      body:JSON.stringify({})
    }).then((res) => res.json());
  };
   




  const redirectToStripe = async(event) => {
  

    //var stripe =  Stripe(process.env.REACT_APP_KEY);
    const stripe = await stripePromise;
    const { sessionId } = await fetchCheckoutSession();
    console.log('sessionId: ' , sessionId);

    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });

  }



  return(
    <div className = "Checkout">
     
    <Helmet>
      <title>Checkout</title>
      <script src="https://js.stripe.com/v3/"></script>          
    </Helmet>


     
      <h1>checkout</h1>
      <button className = "button" onClick={redirectToStripe}>
      buy
      </button>


    </div>

  )

}

export default Checkout;