
import React,  {useState} from 'react';
import './../Dashboard.css'
import useSelection from 'antd/lib/table/hooks/useSelection';


function StudentDashboard() {

  const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)


  return (

    isChinese == 0 ? 

    <div className = "Dashboard">
      <h2>上课</h2>
      <div className = "DashboardGroup">
  
      
      <a href={`/ManagePersonalInfo`}>
        <div className = "DashboardItem">

        <h2>个人信息</h2>
        <h3>修改个人信息，上传照片 </h3>

        </div>
      </a>


      <a href={`/StudentSchedule`}>
        <div className = "DashboardItem">

        <h2>课程表</h2>
        <h3>查看已经报名的课程 </h3>
        </div>

      </a>

    </div>
    </div>

    :


    <div className = "Dashboard">
      <h2>Learning</h2>
      <div className = "DashboardGroup">
  
      
      <a href={`/ManagePersonalInfo`}>
        <div className = "DashboardItem">

        <h2>Manage my personal info</h2>
        <h3>Edit your personal info and profile picture  </h3>

        </div>
      </a>


      <a href={`/StudentSchedule`}>
        <div className = "DashboardItem">

        <h2>My schedule</h2>
        <h3>Check your learning schedule here </h3>
        </div>

      </a>

    </div>
    </div>

  );
}

export default StudentDashboard;