  
import React, { useState } from 'react'
import { Input } from 'antd';

const { Search } = Input;

function SearchFeature(props) {

    const [SearchTerms, setSearchTerms] = useState("")

    const onChangeSearch = (event) => {
        setSearchTerms(event.target.value)
        
        props.refreshFunction(event.target.value)

       
    }

    return (
        <div>
            <Search
               
                value={SearchTerms}
                onChange={onChangeSearch}
                placeholder="what's her/his/their username?"
                style={{ width: 330, height : 40 }}
            />
        </div>
    )
}
  
export default SearchFeature