import React, {useState, useEffect} from 'react';
import firebase from '../../firebase';
import {Link} from 'react-router-dom';
import 'antd/dist/antd.css';
import './ManagePersonalInfo.css'
import { useHistory } from 'react-router-dom';


  const ManagePersonalInfo = () => {
    const history = useHistory();
    const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)


    useEffect(() => {
      featchPersonalInfo()

    }, [])
    const db = firebase.firestore()

    const [PersonalInfo, setPersonalInfo] = useState([])



    function featchPersonalInfo() {

      let uid;

      var user = firebase.auth().currentUser;
  
      if (user) {
        // User is signed in.
        uid = firebase.auth().currentUser.uid
      } else {
        // No user is signed in.
        window.location.replace("https://sincesunday.com/SignInOrSignUp");
  
      }


      var docRef = db.collection("persons").doc(uid);

      docRef.get().then(function(doc) {
          if (doc.exists) {
              console.log("person data:", doc.data());
              setPersonalInfo(doc.data())
          } else {
              // doc.data() will be undefined in this case
              console.log("No such person!");
          }
      }).catch(function(error) {
          console.log("Error getting person data:", error);
      });


    }


  
    return (

      isChinese == 0 ?


      <div className = "ManagePersonalInfoGroup">
        <h2>个人信息</h2>

      <h3>用户名</h3>
      <h5>{PersonalInfo.username}</h5>
    

      <h3>名 </h3>
      <h5>{PersonalInfo.firstName}</h5>
     
      

      <h3>姓 </h3>
      <h5>{PersonalInfo.lastName}</h5>
     

      <h3>微信</h3>
      <h5>{PersonalInfo.email}</h5>
      


      <h3>头像 </h3>
      <img src={PersonalInfo.pic}></img>
      
     
      <button className="editbutton" onClick={() => history.push('/EditPersonalInfo')}>
        编辑
      </button>

      </div>


      :


      <div className = "ManagePersonalInfoGroup">
        <h2>Personal info</h2>

      <h3>User name  </h3>
      <h5>{PersonalInfo.username}</h5>
    

      <h3>First name </h3>
      <h5>{PersonalInfo.firstName}</h5>
     
      

      <h3>Last name </h3>
      <h5>{PersonalInfo.lastName}</h5>
     

      <h3>Email</h3>
      <h5>{PersonalInfo.email}</h5>
      


      <h3>Profile pic </h3>
      <img src={PersonalInfo.pic}></img>
      
     
      <button className="editbutton" onClick={() => history.push('/EditPersonalInfo')}>
        EDIT
      </button>

      </div>
    );
  };







export default ManagePersonalInfo;