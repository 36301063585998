
import React, {useState, useEffect} from 'react';
import firebase from '../firebase';
import ClassComponent from './ClassComponent';
import './InstructorDetail.css'




function InstructorDetail (props) {

    const [Product, setProduct] = React.useState([])
    const [Courses, setCourses] = React.useState([])
    const instructorId = props.match.params.id
    console.log("instructorId : ", instructorId)


    useEffect(() => {
        getDataNew()

    }, [])

    const getDataNew = async() => {
        const db = firebase.firestore()
        var docRef = await db.collection("persons").doc(instructorId);
        docRef.get().then(function(doc) {
            if (doc.exists) {
                setProduct(doc.data())
            } else {
                console.log("No such document in get data new !");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });


        // fetch courses data from collection classes  by instructor id 
        const citiesRef = db.collection('classes');
        const snapshot = await citiesRef.where('instructorId', '==', instructorId).get();
        if (snapshot.empty) {
            console.log('NO CLASSES FOR THIS INSTRUCTOR.');
            return;
        }
        let arr = [];
        snapshot.forEach(doc => {
            console.log(doc.id, '=>', doc.data());
            arr.push(doc.data())
        });
        setCourses(arr)
        console.log('arr', arr);
    }

    const renderCourses = Courses.map((course) => {
        if(course.classStatus !== "cancelled"){
            return (
                <div>
                  
                  <ClassComponent
                    url={course.id}
                    title = {course.className}
                    price = {course.price}
                   
                  />
                 
                </div>
              )

        }


      })


    return (
        <div className = "InstructorInfoGroup">
           
           <img src={Product.pic} />

           <h2>{Product.firstName}</h2>


           {renderCourses}
           
          
        </div>
    );
}
export default InstructorDetail;

