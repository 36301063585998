import React, {useState, useEffect} from 'react';
import firebase from '../firebase';
import SearchFeature from './SearchFeature';
import InstructorComponent from './InstructorComponent';
import 'firebase/firestore';
import './Home.css'
import { Console } from 'console';

const moment = require('moment-timezone'); 


if(process.env.REACT_APP_CONSOLE_LOG == 0){
  console.log = function () {};
}


function Home() {
  var chineseInstructors = ["RKI7NFFWuBQkpeCIafTY4Uw6XBc2", "zfjKwz0ga7WKVB0eRSG3Hv01vpJ3", "GEUmURUO1TTZOQ49zYHUsnIa5gz2"];
  const pic = "https://firebasestorage.googleapis.com/v0/b/peachierwebsite.appspot.com/o/images%2FBook%20a%20zoom%20class%20from%20your%20inspiration.png?alt=media&token=49f7e2c7-535e-4838-a0df-21e586230600"

  //const pic = "https://firebasestorage.googleapis.com/v0/b/peachierwebsite.appspot.com/o/images%2Fdance.mov?alt=media&token=1de84a99-eca7-4402-9396-21e1c08452b5"
  
  const chinesePic = "https://firebasestorage.googleapis.com/v0/b/peachierwebsite.appspot.com/o/images%2F%E5%9C%A8%E5%AE%B6%E5%B0%B1%E5%8F%AF%E4%BB%A5%E4%B8%8A%E7%91%9C%E4%BC%BD%E8%AF%BE.png?alt=media&token=48544e4c-351e-459b-9369-71d1885bd5d1"


  const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)
  

  useEffect(() => {
    getAllInstructors()

  },[])



  console.log("we want to know", process.env.REACT_APP_IS_CHINESE)
  
  const [Instructors, setInstructors] = useState([])
  const [searchResult, setSearchResult] = useState([])

  const updateSearchTerms = (newSearchTerm) =>{
    
      getInstructorbyInstructorName(newSearchTerm)

  }

  const renderSearchResult = searchResult.map((instructor) => {

    return (
      <div>
        
        <InstructorComponent
          url={instructor.id}
          title = {instructor.firstName}
         
          image={instructor.pic}
          
        />
       
      </div>
    )
  })


  const renderInstructorProfiles = Instructors.map((instructor) => {

    return (
      <div>
        
        <InstructorComponent
          url={instructor.id}
          title = {instructor.firstName}
         
          image={instructor.pic}
          
        />
       
      </div>
    )
  })



const getInstructorbyInstructorName = async(newSearchTerm) => {
  const db = firebase.firestore()
    if(newSearchTerm == ""){
      console.log("newSearchTerm is empty");
      
    }else {

      const docRef = db.collection('persons');
      const snapshot = await docRef.where('username', '==', newSearchTerm).get();
      if (snapshot.empty) {
        console.log('No matching documents.');
        return;
      }
      snapshot.forEach(doc => {
        console.log(doc.id, '=>', doc.data());
        let arr = [];
        arr.push(doc.data())
                
        setSearchResult(arr)
      });
    }
  }


  const getAllInstructors = async() => {
    let arr = [];
    const db = firebase.firestore()
    

    if(isChinese == 0){
      for (const chineseInstructor of chineseInstructors){
        await db.collection("persons").where("id", "==", chineseInstructor)
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                console.log(doc.id, " => ", doc.data());
                arr.push(doc.data())
            });
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error);
        });

      }


    


    }else {
      await db.collection("persons").get().then(function(querySnapshot) {
        
        querySnapshot.forEach(function(doc) {
            if(doc.data().showAtFrontPage){
              arr.push(doc.data())
            }
            console.log(doc.id, " => ", doc.data());
        });
      });
    }

    setInstructors(arr)
}



  


  return (

    isChinese == 0 ?

    <div>
    
    <div className = "SearchGroup">

    <img src= {chinesePic}    width="850" height="600"  className = "frontpage" />
    
    </div>







    <div className = "InstructorGroup">
     
      <h2>立刻行动</h2>
      

      <div className = "Instructors">
        {renderInstructorProfiles}
      </div>
    
    </div>
  </div>


:
    <div>
    


      <div className = "SearchGroup">

      <img src= {pic}    width="1000" height="800"  className = "frontpage" />
      
      </div>










      <div className = "InstructorGroup">
       
        <h2>Act now, without delay</h2>
        

        <div className = "Instructors">
          {renderInstructorProfiles}
        </div>
      
      </div>
    </div>
    

    
  );
}

export default Home;
