import React, {useState, useEffect} from 'react';
import firebase from './firebase'



function StudentList(props) {

    const[studentInfo, setStudentInfo] = useState([])
    const db = firebase.firestore()
    const sessionId = props.match.params.id


    useEffect(() => {
        getStudentData()
        
    }, [])

    const getStudentData = async() => {
        db.collection('sessions').doc(sessionId).collection('studentList').get().then(function(querySnapshot) {
            let arr = []
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                arr.push(doc.data())
                
            });
            setStudentInfo(arr)
        });
    }

    const renderStudentInfo = studentInfo.map((s) => {

        return (
          
          <div>
              <span class="nowrap">{s.studentName}  -  </span>
              <span class="nowrap">   {s.studentEmail}</span>

            
          </div>
      
        )
      })
    


  return (
    <div>
      <h1>Student List</h1>
      {renderStudentInfo}
    
    </div>
  );
}

export default StudentList;