
import React from 'react';

import './TransactionRecordItemComponent.css'


const TransactionRecordItemComponent = props => {
   

    return (
        <div className = "TransactionRecordItemComponent">

            <h3>{props.item.className}</h3>
            <h4>Transaction Id : {props.item.transactionId}</h4>
            <h4>Student Id : {props.item.studentId}</h4>
            <h4>Class Date : {props.item.classDate}</h4>
            
            
            


        </div>

    )


}

export default TransactionRecordItemComponent