import React, {useState, useEffect} from 'react';
import firebase from '../../firebase';
import {Link} from 'react-router-dom';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import './EditClassDetail.css'
import { InputNumber } from 'antd';
import { useHistory } from "react-router";
import { Spin } from 'antd';


function EditClassDetail(props) {
    const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)

    const [Course, setCourse] = useState([])
    const classID = props.match.params.id
    const history = useHistory();
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    
    //console.log("classID : ", classID)
    const db = firebase.firestore()

    const [instructorId, setInstructorId] = useState('');
    const [instructorEmail, setInstructorEmail] = useState('');
    const [className, setClassName] = useState('');
    const [maxNumberStudents, setMaxNumberStudents] = useState("");
    const [price, setPrice] = useState('');
    const [prerequisite, setPrerequisite] = useState('');
    const [aboutClass, setAboutClass] = useState('');
    const [duration, setDuration] = useState('');
    const [id, setClassId] = useState('');
    const [stripeAccount, setStripeAccount] = useState('');
    const [classStatus, setClassStatus] = useState('');


    let [classNameError, setClassNameError] = useState("")
    let [maxNumberStudentsError, setMaxNumberStudentsError] = useState("")
    let [classPriceError, setClassPriceError] = useState("")
    let [durationError, setDurationError] = useState("")
    let [prerequisiteError, setPrerequisiteError] = useState("")
    let [aboutClassError, setAboutClassError] = useState("")



    function onChangeMaxNumberStudents(value) {

      maxNumberStudentsError = value == null || value.length == 0 ? "enter a number" : "";
      setMaxNumberStudents(value)
      setMaxNumberStudentsError(maxNumberStudentsError)
    }



    function onChangeClassPrice(value) {
      classPriceError = value == null || value.length == 0 ? "enter a number" : "";
      setPrice(value)
      console.log("classPriceError : " , classPriceError)
      console.log("classPrice: " , value)
      setClassPriceError(classPriceError)

    }

    function onChangeClassDuration(value) {
      durationError = value == null || value.length == 0 ? "enter a number" : "";
      setDuration(value)
      setDurationError(durationError)
    }
    

    useEffect(() => {
        getData()
        
    }, [])


    const getData = async() => {
        
        var docRef = await db.collection("classes").doc(classID);

        docRef.get().then(function(doc) {
            if (doc.exists) {
                setCourse(doc.data())
                setClassName(doc.data().className)
                setMaxNumberStudents(doc.data().maxNumberStudents)
                setPrice(doc.data().price)
                setPrerequisite(doc.data().prerequisite)
                setAboutClass(doc.data().aboutClass)
                setDuration(doc.data().duration)
                setInstructorId(doc.data().instructorId)
                setInstructorEmail(doc.data().instructorEmail)
                setClassId(doc.data().id)
                setStripeAccount(doc.data().stripeAccount)
                setClassStatus(doc.data().classStatus)

                
            } else {
                console.log("No such document in get data new !");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }


    const formValid = () => {
      let valid = true;

      if
      (classNameError.length > 0 || 
        maxNumberStudentsError.length > 0 || 
        classPriceError.length > 0 || 
        durationError.length > 0 || 
        prerequisiteError.length > 0 || 
        aboutClassError.length > 0)

      {
          valid = false
      }


      // validate form is filled out
      // if any of the field is empty, then can't submit the form, so invalid 


      if(className.length == 0 ||
        maxNumberStudents == null ||
        maxNumberStudents.length == 0 ||
        price == null || 
        price.length == 0 ||
        duration == null ||
        duration.length == 0 ||
        aboutClass.length == 0 ||
        prerequisite.length == 0

      ){
          valid = false
      }

      return valid
  }

    const handleSubmit = e => {
      e.preventDefault();
      if(formValid()){
          setSpinnerLoading(true)
          handleSave()
  
          
  
      } else {
          console.log(`form invalid - display error message`)
          isChinese == 0 ?
          window.alert("请填好每一栏的信息")
  
          :
          window.alert("please make sure to fill up every field on this page")
  
      }
  }


  const handleChange = e => {
    //e.preventDefault();
    const {name, value} = e.target;


    switch (name) {

        case 'className':
          classNameError = value.length < 3 ? "minimum 3 characters required" : "";
            setClassName(value)
            
        break;

        case 'prerequisite':
          prerequisiteError = value.length < 3 ? "minimum 3 characters required" : "";
          setPrerequisite(value)
          
        break;

        case 'aboutClass':
          aboutClassError = value.length < 3 ? "minimum 3 characters required" : "";
          setAboutClass(value)
          
        break;


    }
    setClassNameError(classNameError)
    setPrerequisiteError(prerequisiteError)
    setAboutClassError(aboutClassError)
  }
  



    const handleSave = () => {

        

        db.collection('classes').doc(classID).set({
            className: className,
            price: price,
            maxNumberStudents: maxNumberStudents,
            prerequisite: prerequisite, 
            aboutClass: aboutClass, 
            duration: duration, 
            instructorId: instructorId, 
            instructorEmail: instructorEmail,
            id: id,
            stripeAccount: stripeAccount,
            classStatus: classStatus
        })
        .then(function() {
            console.log("Document successfully written!");
            isChinese == 0 ? 
            window.alert("课程内容已保存")
            :

            window.alert("Class detail saved")


            history.goBack()
        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        });
        
    }

    const updateMaxSize = (sessionId) => {
      var washingtonRef = db.collection("sessions").doc(sessionId);

      // Set the "capital" field of the city 'DC'
      return washingtonRef.update({
          maxNumberStudents: maxNumberStudents
      })
      .then(function() {
          console.log("Document successfully updated!");
      })
      .catch(function(error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
      });
    }

    const updateSizeInSession = () => {
      

      db.collection("sessions").where("classId", "==", id)
      .get()
      .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
              const sessionId = doc.data().id
              // update the max student size 
              updateMaxSize(sessionId)



          });
      })
      .catch(function(error) {
          console.log("Error getting documents: ", error);
      });
    }


  return (

    isChinese == 0 ?


    
    <div>

    <form onSubmit={handleSubmit}>


      <div className = "ClassDetailGroup">

      <h2>编辑课程内容</h2>
      


      <label htmlFor="name">课程名称</label>
      <input type="text" placeholder="Class name" name = "className" value={className}
        onChange = {handleChange} />
      {classNameError.length > 0 && (
              <span className = "errorMessage">{classNameError}</span>

      )}
     
     

      <label htmlFor="maxNumberStudents">人数限制</label>
      <InputNumber min={1} max={100} value={maxNumberStudents} onChange={onChangeMaxNumberStudents} />
      {maxNumberStudentsError.length > 0 && (
              <span className = "errorMessage">{maxNumberStudentsError}</span>
      )}



      <label htmlFor="price">价格</label>
      <InputNumber min={1} max={300} value={price} onChange={onChangeClassPrice} />
      {classPriceError.length > 0 && (
              <span className = "errorMessage">{classPriceError}</span>
      )}



      <label htmlFor="Duration">时长</label>
      <InputNumber min={1} max={300} value={duration} onChange={onChangeClassDuration} />
      {durationError.length > 0 && (
              <span className = "errorMessage">{durationError}</span>
      )}




      <label htmlFor="AboutClass">课程内容</label>
      <TextareaAutosize
       value={aboutClass}
       aria-label="minimum height"
       name = "aboutClass"
       rowsMin={3}
       placeholder="课程内容"
       onChange = {handleChange}
      />
      {aboutClassError.length > 0 && (
              <span className = "errorMessage">{aboutClassError}</span>
      )}
    



      <label htmlFor="Prerequisite">参加此课程的基础前提</label>
      <TextareaAutosize
         value={prerequisite}
         aria-label="minimum height"
         rowsMin={3}
         placeholder="参加此课程的基础前提"
         name = "prerequisite"
         onChange={handleChange} 
      />
      {prerequisiteError.length > 0 && (
              <span className = "errorMessage">{prerequisiteError}</span>
      )}
      
       
      <div>
      {spinnerLoading ?  <Spin /> : <input type="submit" value="保存" />} 

      </div>


      </div>
    </form>

     

    </div>



    :



    <div>

    <form onSubmit={handleSubmit}>


      <div className = "ClassDetailGroup">

      <h2>Edit class detail</h2>
      


      <label htmlFor="name">Class name</label>
      <input type="text" placeholder="Class name" name = "className" value={className}
        onChange = {handleChange} />
      {classNameError.length > 0 && (
              <span className = "errorMessage">{classNameError}</span>

      )}
     
     

      <label htmlFor="maxNumberStudents">Max number of students</label>
      <InputNumber min={1} max={100} value={maxNumberStudents} onChange={onChangeMaxNumberStudents} />
      {maxNumberStudentsError.length > 0 && (
              <span className = "errorMessage">{maxNumberStudentsError}</span>
      )}



      <label htmlFor="price">Price</label>
      <InputNumber min={1} max={300} value={price} onChange={onChangeClassPrice} />
      {classPriceError.length > 0 && (
              <span className = "errorMessage">{classPriceError}</span>
      )}



      <label htmlFor="Duration">Duration</label>
      <InputNumber min={1} max={300} value={duration} onChange={onChangeClassDuration} />
      {durationError.length > 0 && (
              <span className = "errorMessage">{durationError}</span>
      )}




      <label htmlFor="AboutClass">About Class</label>
      <TextareaAutosize
       value={aboutClass}
       aria-label="minimum height"
       name = "aboutClass"
       rowsMin={3}
       placeholder="AboutClass"
       onChange = {handleChange}
      />
      {aboutClassError.length > 0 && (
              <span className = "errorMessage">{aboutClassError}</span>
      )}
    



      <label htmlFor="Prerequisite">Prerequisite</label>
      <TextareaAutosize
         value={prerequisite}
         aria-label="minimum height"
         rowsMin={3}
         placeholder="Prerequisite"
         name = "prerequisite"
         onChange={handleChange} 
      />
      {prerequisiteError.length > 0 && (
              <span className = "errorMessage">{prerequisiteError}</span>
      )}
      
       
      <div>
      {spinnerLoading ?  <Spin /> : <input type="submit" value="SAVE" />} 

      </div>


      </div>
    </form>

     

    </div>
  );
}



export default EditClassDetail;