

import React from 'react'
import './ManageClassComponent.css'

const ManageClassComponent = props => (
    <div className = "ManageClassComponent">
       
        

        <a href={`/manageClasses/${props.url}`}>

            <div className = "content">
            <h4>{props.title}</h4>
           
            </div>
            
        </a>


    </div>
)

export default ManageClassComponent