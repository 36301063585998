import React, { useState, useEffect } from 'react';
import { Drawer, Button } from 'antd';
import {Link} from 'react-router-dom';
import firebase from './firebase';
import './Account.css';

const Account = () => {
  const [visible, setVisible] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false)
  const [isInstructor, setIsInstructor] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)


  useEffect(() => {
    featchPersonalInfo()

  }, [])
  const db = firebase.firestore()

  let uid;

  const [PersonalInfo, setPersonalInfo] = useState([])


  function featchPersonalInfo() {

    

    var user = firebase.auth().currentUser;

    if (user) {
      // User is signed in.
      uid = firebase.auth().currentUser.uid
      console.log("uid is ", uid)

      var docRef = db.collection("persons").doc(uid);

      docRef.get().then(function(doc) {
          if (doc.exists) {
              console.log("person data:", doc.data());
              setPersonalInfo(doc.data())
              setIsAdmin(doc.data().isAdmin)
              setIsInstructor(doc.data().isInstructor)
          } else {
              // doc.data() will be undefined in this case
              console.log("No such person!");
          }
      }).catch(function(error) {
          console.log("Error getting person data:", error);
      });


    } else {
      // No user is signed in.
      //window.location.replace("https://localhost:3000/SignInOrSignUp");
      //window.location.replace("https://localhost:3000/");

      

    }
  }


  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };



  const signOut = () => {

    localStorage.removeItem("items");
    localStorage.removeItem("totalPrice");
   
    localStorage.clear();
    
    firebase.auth().signOut().then(function() {
      }).catch(function(error) {
      });
  }



    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        setLoggedIn(true)
  
        // user.getIdTokenResult().then(idTokenResult => {
        //   if (idTokenResult.claims.admin){
        //     setIsAdmin(true)

        //   }else {
        //     setIsAdmin(false)
            
        //     if (idTokenResult.claims.instructor){
        //       setIsInstructor(true)
        //     }else{
        //       setIsInstructor(false)
        //     }
        //   }
        // })
      } else {
        // No user is signed in.
        setLoggedIn(false)
      }
    });


  return ( 

    isChinese == 0 ?  
    
    loggedIn ? 

    isAdmin? 
    <>
      <Button type="primary"  style={{ background: "black" ,  borderColor: "black" }} onClick={showDrawer}>
      我的账户
      </Button>

      <Drawer
        title="我的账户"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
       

        <Link className='text-link' to={`/StudentDashboard`} style={{ color: 'black' }} >学习</Link>

        <br></br>
        <br></br>

        <Link className='text-link' to={`/InstructorDashboard`} style={{ color: 'black' }} >教课</Link>
        <br></br>
        <br></br>

        <Link className='text-link' to={`/BecomeAnInstructor`} style={{ color: 'black' }} >想教课？</Link>


        <br></br>
        <br></br>

        <Link className='text-link' to={`/AdminDashboard`} style={{ color: 'black' }}>Admin dashboard</Link>
        <br></br>
        <br></br>

        <button onClick = {() =>signOut()}> Sign out</button>
        
      
      </Drawer>
    </>

    :

    isInstructor ?


    <>
      <Button type="primary"  style={{ background: "black" ,  borderColor: "black" }} onClick={showDrawer}>
      我的账户
      </Button>

      <Drawer
        title="我的账户"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
       
       
       <Link className='text-link' to={`/StudentDashboard`} style={{ color: 'black' }}>学习</Link>

       <br></br>
       <br></br>
      


       <Link className='text-link' to={`/InstructorDashboard`} style={{ color: 'black' }}>教课</Link>


      
       <br></br>
       <br></br>
       




       <button onClick = {() =>signOut()}>退出</button>

    

        
      
      </Drawer>
    </>


    :
   

    <>
      <Button type="primary"  style={{ background: "black" ,  borderColor: "black" }} onClick={showDrawer}>
      我的账户
      </Button>

      <Drawer
        title="我的账户"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
       

        <Link className='text-link' to={`/StudentDashboard`} style={{ color: 'black' }}>学习</Link>

        <br></br>
        <br></br>

        <Link className='text-link' to={`/BecomeAnInstructor`} style={{ color: 'black' }}>想教课？</Link>

        <br></br>
        <br></br>

  

        <button onClick = {() =>signOut()}> Sign out</button>
        
      
      </Drawer>
    </>



    :
    



    <>
      <Button type="primary" style={{ background: "black" ,  borderColor: "black" }} onClick={showDrawer}>
      我的账户
      </Button>

      <Drawer
        title="我的账户"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <Link className='text-link' to={`/SignInOrSignUp`} style={{ color: 'black' }}>登录</Link>

      </Drawer>
    </>




    :


    loggedIn ? 

    isAdmin? 
    <>
      <Button type="primary"  style={{ background: "black" ,  borderColor: "black" }} onClick={showDrawer}>
        Account
      </Button>

      <Drawer
        title="Account"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
       

        <Link className='text-link' to={`/StudentDashboard`} style={{ color: 'black' }} >Learning</Link>

        <br></br>
        <br></br>

        <Link className='text-link' to={`/InstructorDashboard`} style={{ color: 'black' }} >Teaching</Link>
        <br></br>
        <br></br>

        <Link className='text-link' to={`/BecomeAnInstructor`} style={{ color: 'black' }} >Become an instructor</Link>


        <br></br>
        <br></br>

        <Link className='text-link' to={`/AdminDashboard`} style={{ color: 'black' }}>Admin dashboard</Link>
        <br></br>
        <br></br>

        <button onClick = {() =>signOut()}> Sign out</button>
        
      
      </Drawer>
    </>

    :

    isInstructor ?


    <>
      <Button type="primary"  style={{ background: "black" ,  borderColor: "black" }} onClick={showDrawer}>
        Account
      </Button>

      <Drawer
        title="Account"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
       
       
       <Link className='text-link' to={`/StudentDashboard`} style={{ color: 'black' }}>Learning</Link>

       <br></br>
       <br></br>
      


       <Link className='text-link' to={`/InstructorDashboard`} style={{ color: 'black' }}>Teaching</Link>


      
       <br></br>
       <br></br>
       




       <button onClick = {() =>signOut()}> Sign out</button>

    

        
      
      </Drawer>
    </>


    :
   

    <>
      <Button type="primary"  style={{ background: "black" ,  borderColor: "black" }} onClick={showDrawer}>
        Account
      </Button>

      <Drawer
        title="Account"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
       

        <Link className='text-link' to={`/StudentDashboard`} style={{ color: 'black' }}>Learning</Link>

        <br></br>
        <br></br>

        <Link className='text-link' to={`/BecomeAnInstructor`} style={{ color: 'black' }}>Become an instructor</Link>

        <br></br>
        <br></br>

  

        <button onClick = {() =>signOut()}> Sign out</button>
        
      
      </Drawer>
    </>



    :
    



    <>
      <Button type="primary" style={{ background: "black" ,  borderColor: "black" }} onClick={showDrawer}>
        Account
      </Button>

      <Drawer
        title="Account"
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <Link className='text-link' to={`/SignInOrSignUp`} style={{ color: 'black' }}>Sign in Or Sign up</Link>

      </Drawer>
    </>
  );
};


export default Account;