import {Link} from 'react-router-dom';
import React from 'react';
import firebase from '../firebase'
import '../Dashboard.css'



function AdminDashboard() {

 
  return (
    <div className = "Dashboard">
      <h2>Admin Dashboard</h2>
      <div className = "DashboardGroup">


      <a href={`/AddInstructor`}>
        <div className = "DashboardItem">

        <h2>Add instructor</h2>
        <h3>Add instructors  </h3>
        </div>

       </a>


       <a href={`/AddAdminRole`}>
        <div className = "DashboardItem">

        <h2>Add admin</h2>
        <h3>Add admins  </h3>
        </div>

       </a>
     </div>





      
     
    </div>
  );
}

export default AdminDashboard;