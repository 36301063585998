
import React from 'react'
import './ClassComponentUnderInstructorSchedule.css'
import moment from 'moment';
import { useState, useEffect} from 'react';

const ClassComponentUnderInstructorSchedule = props => {


    const [finalDateAndTime, setFinalDateAndTime] = useState("")

    const convertToFinalDate = () => {
        // original input
        const DATE = props.session.date;
        const TIME = props.session.time;
        const dateAndTime = DATE + " " + TIME;
        const TIMEZONE = props.session.timeZone;
        var final;
        
    
        // when we need to form everything together from the original input 
        var instructorTimeAndDateAndTimezone = moment.tz(dateAndTime, TIMEZONE);
        
    
        // when we have the local time zone input from user 
        var selectedTimeZone = props.selectedTimeZone
        var isChinese = props.isChinese
    
        var userTimeAndDateAndTimezone  = instructorTimeAndDateAndTimezone.clone().tz(selectedTimeZone);
        
    
        // display the time in local time zone 
        if(isChinese == 0){
            final = userTimeAndDateAndTimezone.locale('zh-cn').format('llll') 
            
        }else {
            final = userTimeAndDateAndTimezone.locale('en').format('llll')
        }
    
        console.log("final : ", final)
    
        
        setFinalDateAndTime(final)
       
  
      
      
    }

    useEffect(() => {
        
    
        convertToFinalDate()

    },[props])


    return (
        props.session.sessionStatus === "cancelled" ? 

        <div className = "ClassComponentUnderInstructorSchedule">
           
            
    
            <a href={`/ClassDetailUnderInstructorSchedule/${props.url}`}>
            <div className = "ssssss">
               
                <h4>{props.session.className}</h4>
                <h4>{"cancelled"}</h4>
                <h4>{finalDateAndTime}</h4>
            </div>
                
    
             
                
            </a>
    
    
        </div>
    
        :
    
        <div className = "ClassComponentUnderInstructorSchedule">
           
            
          
            <a href={`/ClassDetailUnderInstructorSchedule/${props.url}`}>
    
            <div className = "ssssss">
                <h4>{props.session.className}</h4>
                <h4>{finalDateAndTime}</h4>
            </div>
    
              
                
            </a>
    
    
        </div>

    )

}


   


export default ClassComponentUnderInstructorSchedule