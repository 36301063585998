import "./Nav.css"
import Account from "./Account"
import React, {useState, useEffect} from 'react';

function Nav() {

  const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)

  return (
    isChinese == 0 ? 
    <nav className = "NavGroup">
      <ul className="LinksGroup">

        <li><a href={`/`}>周末黑板报</a></li>




        <li><Account></Account></li>
      </ul>
    </nav>


    :

    <nav className = "NavGroup">
      <ul className="LinksGroup">

        <li><a href={`/`}>sincesunday</a></li>




        <li><Account></Account></li>
      </ul>
    </nav>
  )
}

export default Nav
