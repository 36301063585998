import './Careers.css'
import React from 'react';



function Careers() {

   
  return (
    <div className = "CareerPage">
      <h2>Careers</h2>

     

      <p> We are looking for a full stack engineer. Our tech stack : React + Node + Google Cloud. This is a fully remote role. Please email your resume to comejoinus@sincesunday.com if interested</p>

    </div>
  );
}

export default Careers;