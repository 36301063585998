import firebase from '../../firebase'
import React, { useState, useEffect } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import './AddClassDetail.css'
import { InputNumber } from 'antd';
import { Spin } from 'antd';
import { useHistory } from "react-router";

  const AddClassDetail = () => {
    const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)

    const db = firebase.firestore()
    let uid;
    let email;

    var user = firebase.auth().currentUser;
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const history = useHistory();
    

    if (user) {
      // User is signed in.
      uid = firebase.auth().currentUser.uid
      email = firebase.auth().currentUser.email
    } else {
      // No user is signed in.
      window.location.replace("https://sincesunday.com/SignInOrSignUp");

    }


    const [className, setClassName] = useState( '' );
    const [maxNumberStudents, setMaxNumberStudents] = useState( '' );
    const [price, setPrice] = useState( '' );
    const [duration, setDuration] = useState( '' );
    const [aboutClass, setAboutClass] = useState( '' );
    const [prerequisite, setPrerequisite] = useState( '' );
    const [stripeAccount, setStripeAccount] = useState( '' );

    let [classNameError, setClassNameError] = useState("")
    let [maxNumberStudentsError, setMaxNumberStudentsError] = useState("")
    let [classPriceError, setClassPriceError] = useState("")
    let [durationError, setDurationError] = useState("")
    let [prerequisiteError, setPrerequisiteError] = useState("")
    let [aboutClassError, setAboutClassError] = useState("")


    const formValid = (classNameError,maxNumberStudentsError, classPriceError, durationError, prerequisiteError, aboutClassError, 
      className, maxNumberStudents, price, duration, aboutClass, prerequisite) => {
      let valid = true;

      // validate form error
      // if any of them has length > 0, then it's not valid 


      if
      (classNameError.length > 0 || 
        maxNumberStudentsError.length > 0 || 
        classPriceError.length > 0 || 
        durationError.length > 0 || 
        prerequisiteError.length > 0 || 
        aboutClassError.length > 0)

      {
          valid = false
      }

      // validate form is filled out
      // if any of the field is empty, then can't submit the form, so invalid 


      if(className.length == 0 ||
        maxNumberStudents.length == 0 ||
        price.length == 0 ||
        duration.length == 0 ||
        aboutClass.length == 0 ||
        prerequisite.length == 0

      ){
          valid = false
      }

      return valid
  }
    

  const handleSubmit = e => {
    e.preventDefault();
    if(formValid(classNameError,maxNumberStudentsError, classPriceError, durationError, prerequisiteError, aboutClassError, 
      className, maxNumberStudents, price, duration, aboutClass, prerequisite)){
        // do the sign up steps , like register this user with firebase 
        console.log(`we are at handleSave()`)
        setSpinnerLoading(true)
        handleSave()

        

    } else {
        console.log(`form invalid - display error message`)
        isChinese == 0 ?
        window.alert("请确保填好每一栏的信息")

        :

        window.alert("please make sure to fill up every field on this page")

    }
}



    const handleChange = e => {
      //e.preventDefault();
      const {name, value} = e.target;


      switch (name) {

          case 'className':
              classNameError = value.length < 3 ? "minimum 3 characters required" : "";
              setClassName(value)
              
          break;




          case 'prerequisite':
            prerequisiteError = value.length < 3 ? "minimum 3 characters required" : "";
            setPrerequisite(value)
            
          break;

          case 'aboutClass':
            aboutClassError = value.length < 3 ? "minimum 3 characters required" : "";
            setAboutClass(value)
            
          break;


      }
      setClassNameError(classNameError)
      setPrerequisiteError(prerequisiteError)
      setAboutClassError(aboutClassError)
    }




    useEffect(() => {
      getCurrentInstructorStripeAccount()

    }, []);

    function onChangeMaxNumberStudents(value) {

      maxNumberStudentsError = value == null || value.length == 0 ? "enter a number" : "";
      setMaxNumberStudents(value)
      setMaxNumberStudentsError(maxNumberStudentsError)

    
    }

    function onChangeClassPrice(value) {
      classPriceError = value == null || value.length == 0 ? "enter a number" : "";
      setPrice(value)
      setClassPriceError(classPriceError)

    }
    

    function onChangeClassDuration(value) {
      durationError = value == null || value.length == 0 ? "enter a number" : "";
      setDuration(value)
      setDurationError(durationError)
    }
    
  
    const getCurrentInstructorStripeAccount = async() => {
      // get current instructor's stripe account and set it to stripeAccount
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // User logged in already or has just logged in.
          console.log(user.uid);
          

          var docRef = db.collection("persons").doc(user.uid);

          docRef.get().then(function(doc) {
              if (doc.exists) {
                  console.log("Document data:", doc.data());
                  setStripeAccount(doc.data().stripeAccount)
              } else {
                  // doc.data() will be undefined in this case
                  console.log("No such document in add a class!");
              }
          }).catch(function(error) {
              console.log("Error getting document:", error);
          });

        } else {
          // User not logged in or has just logged out.
          console.log("User not logged in or has just logged out");
        }
      });
    }





    const handleSave = () => {
     
      if (firebase.auth().currentUser !== null) {
        

        const randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        db.collection("classes").doc(randomString).set({
          instructorId: uid, 
          id:randomString,
          className: className,
          maxNumberStudents: maxNumberStudents,
          price: price, 
          duration: duration, 
          aboutClass: aboutClass, 
          prerequisite: prerequisite, 
          stripeAccount: stripeAccount,
          classStatus: "available", 
          instructorEmail: email

          
      })
      .then(function() {
          console.log("Document successfully written!");
          isChinese == 0 ?
          window.alert("新课程已创建")
          :

          window.alert("new class created")
          history.goBack()
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      });
      

      }else {
        console.log("current user is null ")
      }

    }


  
    return (

      isChinese == 0 ? 

      <div >
      <form onSubmit={handleSubmit}>
        <div className = "CreateClassGroup" >

        

      <h2>创建一个新课</h2>
     

      <label htmlFor="className">课程名称</label>
        <input type = "text" className = " " placeholder = "课程名称" type = "text" name = "className" noValidate onChange = {handleChange}></input>
        {classNameError.length > 0 && (
            <span className = "errorMessage">{classNameError}</span>
        )}
    




      <label htmlFor="maxNumberStudents">人限</label>
      <InputNumber min={1} max={100} onChange={onChangeMaxNumberStudents} />
      {maxNumberStudentsError.length > 0 && (
            <span className = "errorMessage">{maxNumberStudentsError}</span>
      )}



     

      <label htmlFor="price">价格</label>
      <InputNumber min={1} max={300} onChange={onChangeClassPrice} />
      {classPriceError.length > 0 && (
            <span className = "errorMessage">{classPriceError}</span>
      )}

  


      <label htmlFor="duration">时长（分钟）</label>
      <InputNumber min={1} max={300} onChange={onChangeClassDuration} />
      {durationError.length > 0 && (
            <span className = "errorMessage">{durationError}</span>
      )}

  

      <label htmlFor="aboutClass">课程内容</label>
      <TextareaAutosize
      name ="aboutClass"
       value={aboutClass}
       aria-label="minimum height"
       rowsMin={3}
       placeholder="课程内容"
       onChange = {handleChange}
      />
      {aboutClassError.length > 0 && (
            <span className = "errorMessage">{aboutClassError}</span>
      )}


      <label htmlFor="prerequisite">参加此课程的基础前提</label>
      <TextareaAutosize
       name ="prerequisite"
       value={prerequisite}
       aria-label="minimum height"
       rowsMin={3}
       placeholder="参加此课程的基础前提"
       onChange = {handleChange}
      />
      {prerequisiteError.length > 0 && (
            <span className = "errorMessage">{prerequisiteError}</span>
      )}



    <div>
    {spinnerLoading ?  <Spin /> : <input type="submit" value="保存" />} 

    </div>


      </div>

      </form>

    </div>


    :



      <div >
        <form onSubmit={handleSubmit}>
          <div className = "CreateClassGroup" >

          

        <h2>Create a class</h2>
       

        <label htmlFor="className">Class name</label>
          <input type = "text" className = " " placeholder = "class name" type = "text" name = "className" noValidate onChange = {handleChange}></input>
          {classNameError.length > 0 && (
              <span className = "errorMessage">{classNameError}</span>
          )}
      




        <label htmlFor="maxNumberStudents">Max number of students</label>
        <InputNumber min={1} max={100} onChange={onChangeMaxNumberStudents} />
        {maxNumberStudentsError.length > 0 && (
              <span className = "errorMessage">{maxNumberStudentsError}</span>
        )}



       

        <label htmlFor="price">Class price in US dollars</label>
        <InputNumber min={1} max={300} onChange={onChangeClassPrice} />
        {classPriceError.length > 0 && (
              <span className = "errorMessage">{classPriceError}</span>
        )}

    
 

        <label htmlFor="duration">Class duration in minutes</label>
        <InputNumber min={1} max={300} onChange={onChangeClassDuration} />
        {durationError.length > 0 && (
              <span className = "errorMessage">{durationError}</span>
        )}

    

        <label htmlFor="aboutClass">About this class</label>
        <TextareaAutosize
        name ="aboutClass"
         value={aboutClass}
         aria-label="minimum height"
         rowsMin={3}
         placeholder="about the class"
         onChange = {handleChange}
        />
        {aboutClassError.length > 0 && (
              <span className = "errorMessage">{aboutClassError}</span>
        )}


        <label htmlFor="prerequisite">Class prerequisite</label>
        <TextareaAutosize
         name ="prerequisite"
         value={prerequisite}
         aria-label="minimum height"
         rowsMin={3}
         placeholder="prerequisite"
         onChange = {handleChange}
        />
        {prerequisiteError.length > 0 && (
              <span className = "errorMessage">{prerequisiteError}</span>
        )}



      <div>
      {spinnerLoading ?  <Spin /> : <input type="submit" value="SAVE" />} 

      </div>


        </div>

        </form>

      </div>
    );
  };


export default AddClassDetail;