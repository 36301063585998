import React, {useState, useEffect} from 'react';
import firebase from '../../firebase';
import './ClassDetailUnderInstructorSchedule.css'
import jstz from 'jstz';
import moment from 'moment';

import StudentInfoBox from './StudentInfoBox';



function ClassDetailUnderInstructorSchedule(props) {

    const [isChinese, setIsChinese] = useState(process.env.REACT_APP_IS_CHINESE)

    const db = firebase.firestore()
    const sessionId = props.match.params.id
    const [finalDateAndTime, setFinalDateAndTime] = useState("")
    const [reminderForRefund, setReminderForRefund] = useState("")
    const [selectedTimeZone, setSelectedTimeZone] = useState(jstz.determine().name());
    const [studentInfo, setStudentInfo] = useState([])
    const [session, setSession] = useState([])

    useEffect(() => {
        getSessionData()
        getStudentData()
        
    }, [])

    useEffect(() => {
        getUserDateAndTimeInUserTimeZone(session)
        
    }, [selectedTimeZone])


  


    function timeZoneChange(selectedTimeZone) {

        console.log("time zone", selectedTimeZone);
        setSelectedTimeZone(selectedTimeZone)
    }

    const getSessionData = async() => {

        console.log("sessionId is ", {sessionId});

        const docRef = db.collection('sessions').doc(sessionId)

       
        docRef.get().then(function(doc) {
            if (doc.exists) {
                console.log("Document data:", doc.data());
                setSession(doc.data())
                getUserDateAndTimeInUserTimeZone(doc.data())
                if(doc.data().sessionStatus == "cancelled"){
                    setReminderForRefund("Class is cancelled, please refund your students with the payment intent id on stripe dashboard")
                }
            } else {
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    const getUserDateAndTimeInUserTimeZone = async(sessionData) => {
        const DATE = sessionData.date;
        const TIME = sessionData.time;
        const dateAndTime = DATE + " " + TIME;
        console.log("dateAndTime in instructor's local time is  ", dateAndTime)
        const TIMEZONE = sessionData.timeZone;
        console.log("instructor time zone is  ", TIMEZONE)
        var final;
        

       // when we need to form everything together from the original input 
       var instructorTimeAndDateAndTimezone = moment.tz(dateAndTime, TIMEZONE);

   
       console.log("selectedTimeZone :   ", selectedTimeZone)
       var userTimeAndDateAndTimezone  = instructorTimeAndDateAndTimezone.clone().tz(selectedTimeZone);
       
       
       // display the time in local time zone 
        if(isChinese == 0){
            final = userTimeAndDateAndTimezone.locale('zh-cn').format('llll') 
            
        }else {
            final = userTimeAndDateAndTimezone.locale('en').format('llll')
        }
       console.log("time in userTimeAndDateAndTimezone is  ", final)

       setFinalDateAndTime(final)
    }
    



    const getStudentData = async() => {
        db.collection('sessions').doc(sessionId).collection('studentList').get().then(function(querySnapshot) {
            let arr = []
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                arr.push(doc.data())
                
            });
            setStudentInfo(arr)
        });
    }

    const renderStudentInfo = studentInfo.map((s) => {

        return (


          
          <div className = "studentList">
              <StudentInfoBox

              student = {s}
            />

          </div>
      
        )
      })

    


  return (

    isChinese == 0 ?

    <div className = "ClassDetail">


    <h3>{session.className}</h3>

    <h5>{reminderForRefund}</h5>


    <h5> {selectedTimeZone} 时区</h5>
    <h6>{finalDateAndTime}</h6>
   

    <h5>腾讯会议链接 </h5>
    <h6>{session.zoomLink}</h6>

    <h5>腾讯会议密码</h5>
    <h6>{session.zoomPassword}</h6>


    <h5>学生名单</h5>
    {renderStudentInfo}
    
  </div>

    :


    <div className = "ClassDetail">


      <h3>{session.className}</h3>

      <h5>{reminderForRefund}</h5>


      <h5>We believe you are in {selectedTimeZone} time</h5>
      <h6>{finalDateAndTime}</h6>
     

      <h5>Class link </h5>
      <h6>{session.zoomLink}</h6>

      <h5>Password</h5>
      <h6>{session.zoomPassword}</h6>


      <h5>Student list</h5>
      {renderStudentInfo}
      
    </div>
  );
}

export default ClassDetailUnderInstructorSchedule;